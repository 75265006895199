
import format from 'date-fns/format'
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import getUnixTime from 'date-fns/getUnixTime'
import fromUnixTime from 'date-fns/fromUnixTime'
import { useDispatch, useSelector } from 'react-redux'
import { propEq } from 'ramda'
import { FormInput } from '../../../../common/components'
import { onAddNewEvent, requestRetrievableEvents } from '../../../../actions/events'
import { useFormValidator } from '../../../../common/hooks'
import { sizeOptionsList, statusOptionsList, trashTypeOptionsList } from '../../../../common/constants'
import { formIsFetchingStatus } from '../../../../selectors'

const CopyEventModal = ({ eventToCopy }) => {
  const dispatch = useDispatch()
  const formIsFetching = useSelector(formIsFetchingStatus)
  const [since, setEventSince] = useState(fromUnixTime(eventToCopy.since))
  const [retrievableEvents, setRetrievableEvents] = useState([])
  const [eventStatus, setEventStatus] = useState(eventToCopy.status)
  const [retrieveId, setRetrieveId] = useState('')
  const { onSubmitForm, statusNode } = useFormValidator({})

  const onKeyPress = e => {
    const keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()

      return false
    }
  }

  const handleRequestRetrievableEvents = useCallback(() => {
    if (eventStatus === '1' || eventStatus === '0') {
      dispatch(requestRetrievableEvents({
        location: eventToCopy.location.split('--')[0],
        submitCallback: response => {
          if(response.list && response.list.length) {
            const formattedEvents = response.list.map(event => {
              const benneStatus = statusOptionsList.find(propEq('id', event.status))
              const benneSize = sizeOptionsList.find(propEq('id', event.benne_size))
              const benneTrash = trashTypeOptionsList.find(propEq('id', event.trash_type))
  
              return {
                id: event.id,
                ref_event: event.ref_event,
                label: `${format(new Date(event.since*1000), "MMMM d, yyyy HH:mm")}: ${benneStatus.label} ${benneSize.label} ${benneTrash.label}`
              }
            })
  
            setRetrievableEvents(formattedEvents)
            setRetrieveId(formattedEvents[0].id)
          }
        }
      }))
    }
  }, [eventStatus])

  const handleSubmitForm = e => {
    e.preventDefault()

    dispatch(
      onAddNewEvent({
        formData: {
          ...eventToCopy,
          retrievedEvent: retrieveId ? retrievableEvents.find(propEq('id', Number(retrieveId))) : null,
          since: getUnixTime(since) + 3600,
          driver_id: null,
          has_hour: 0,
          is_payd: 0,  
          is_overtime: 0,
          from_cabinet: Number(!!eventToCopy.from_cabinet),
          with_ttc: Number(!!eventToCopy.with_ttc),
          status: eventStatus,
          location: {
            location_lng: eventToCopy.location_lng,
            location_lat: eventToCopy.location_lat,
            location: eventToCopy.location,
            location_id: eventToCopy.location_id || '',
          },
        },
        submitCallback: (response) => {
          onSubmitForm(response)

          if(response.status === 200) {
            handleRequestRetrievableEvents()
          }
        },
      }),
    )
  }

  useEffect(() => {
    handleRequestRetrievableEvents()
  }, [eventStatus])

  return (
    <form
      onKeyPress={onKeyPress}
      onSubmit={handleSubmitForm}
      className="inputForm modal"
    >
      <FormInput
        disabled
        label="Adress"
        type="text"
        name='location'
        value={eventToCopy.location}
      />

      <div className="formInput">
        <DatePicker
          inline
          locale="fr"
          selected={since}
          onChange={setEventSince}
        />
      </div>

      <div className="formInput">
        <label htmlFor="status">Statut</label>
        <select
          value={eventStatus}
          onChange={e => setEventStatus(e.target.value)}
        >
          {statusOptionsList.map(status => (
            <option key={status.id} value={status.id}>
              {status.label}
            </option>
          ))}
        </select>
      </div>

      {
        !!(eventStatus === '1' || eventStatus === '0') && (
          <div className="formInput">
            <label htmlFor="status">Benne Récupéré</label>
            <select
              value={retrieveId}
              onChange={e => setRetrieveId(e.target.value)}
            >
              {retrievableEvents.map(event => (
                <option key={event.id} value={event.id}>
                  {event.label}
                </option>
              ))}
            </select>
        </div>
        )
      }
      <div className="formFooter">
        {statusNode}
        <button type="submit" className="button" disabled={formIsFetching}>
          submit
        </button>
      </div>
    </form>
  )
}

export default CopyEventModal
