import { CLIENTS } from './types'

export const onAddNewClient = payload => ({
  type: CLIENTS.REQUEST_ADD,
  payload,
})

export const onAddNewClientSucces = payload => ({
  type: CLIENTS.ADD,
  payload,
})

export const onRemoveClient = payload => ({
  type: CLIENTS.REQUEST_REMOVE,
  payload,
})

export const onRemoveClientSucces = payload => ({
  type: CLIENTS.REMOVE,
  payload,
})

export const getClientsListSucces = payload => ({
  type: CLIENTS.GET_LIST,
  payload,
})

export const onUpdateClient = payload => ({
  type: CLIENTS.REQUEST_UPDATE,
  payload,
})

export const onUpdateClientSuccess = payload => ({
  type: CLIENTS.UPDATE,
  payload,
})
