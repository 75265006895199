import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { equals } from 'ramda'
import './style.scss'

const SearchAutocomplete = ({ disabled, options, onChange, value, label }) => {
  const [matchQueryOptions, setMatchQueryOptions] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [query, setQuery] = useState(value)

  useEffect(() => {
    if (!value.length) {
      setQuery(options[0].label)
    }
  }, [options, value])

  useEffect(() => {
    if (!equals(options, matchQueryOptions)) {
      setMatchQueryOptions(options)

      if (options.length && !query.length) {
        setQuery(options[0].label)
      }
    }
  }, [matchQueryOptions, options, query])

  const handleClickOption = useCallback(
    ({ id, label }) => {
      onChange(id)
      setQuery(label)
    },
    [onChange],
  )

  const handleQueryChange = e => setQuery(e.target.value)

  const handleFocus = () => setShowOptions(true)

  const handleBlur = () => setShowOptions(false)

  const renderOptions = useMemo(() => {
    if (showOptions && matchQueryOptions.length) {
      return (
        <ul className="options">
          {options
            .filter(({ label }) =>
              label.toLowerCase().includes(query.toLowerCase()),
            )
            .map(({ label, id }) => (
              <li key={id} onMouseDown={() => handleClickOption({ id, label })}>
                {label}
              </li>
            ))}
        </ul>
      )
    }
  }, [handleClickOption, matchQueryOptions.length, options, query, showOptions])

  return (
    <div className="autocomplete-container">
      <label>{label}</label>

      <input
        type="text"
        value={query}
        disabled={disabled}
        onChange={handleQueryChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {renderOptions}
    </div>
  )
}

SearchAutocomplete.defaultProps = {
  onChange: () => undefined,
  disabled: false,
  options: [],
  value: '',
  label: '',
}

export default SearchAutocomplete
