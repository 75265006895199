import { CHANTIERS } from '../../actions/chantiers/types'

const getChantiersList = action => ({
  isFetching: false,
  list: Object.values(action.payload.list).reduce(
    (acc, group) => [...acc, group],
    [],
  ),
})

function reducer(state = { list: [], isFetching: false }, action) {
  switch (action.type) {
    case CHANTIERS.REQUEST_LIST:
      return {
        isFetching: true,
        list: [],
      }
    case CHANTIERS.GET_LIST:
      return getChantiersList(action)
    default:
      return state
  }
}

export default reducer
