import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const CardItem = ({
  id,
  date,
  amount,
  client,
  location,
  benneSize,
  moveCard,
  removeCard,
  index,
}) => {
  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <div className="invoiceItem" ref={ref} style={{ opacity }}>
      <div className="client">{client.label}</div>
      <p className="details">{location}</p>
      <p className="details">
        {date} | {benneSize.label} | {amount && `${amount}€`}
      </p>
      <div className="closer" onClick={() => removeCard(id)} />
    </div>
  )
}

export default CardItem
