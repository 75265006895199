import React from 'react'
import DatePicker from 'react-datepicker'
import getUnixTime from 'date-fns/getUnixTime'
import { propEq } from 'ramda'

import {
  PlacesAutocomplete,
  SearchAutocomplete,
  FormInput,
} from '../../../../common/components'

import {
  orderCountLabels,
  defaultSelectOption,
  statusOptionsList,
  sizeOptionsList,
  trashTypes,
} from '../../../../common/constants'

import { useForm, useFormValidator } from '../../../../common/hooks'
import { getDistanceToClient } from '../../../../common/utils'

const trashTypeOptions = [defaultSelectOption, ...trashTypes]

const ModalContent = ({
  onSubmit,
  driversList,
  clientsList,
  defaultformData,
  editMode,
  formIsFetching,
}) => {
  const { onSubmitForm, statusNode } = useFormValidator({})

  const {
    values: formData,
    handleChange: setFormData,
    customHandleChange,
    handleResetForm,
  } = useForm(defaultformData)

  const driverOptions = [defaultSelectOption, ...driversList]
  const clientOptions = [defaultSelectOption, ...clientsList]

  const processSubmitResponse = formRef => response => {
    if (response.status === 200) {
      handleResetForm(formRef)
    }
  }

  const handleSubmitForm = e => {
    e.preventDefault()

    const submitFormRef = processSubmitResponse(e.target)

    onSubmit({
      formData: {
        ...formData,
        driver: formData.driver_id !== '' ? formData.driver : null,
        driver_id: formData.driver_id,
        has_hour: Number(!!formData.has_hour),
        with_ttc: Number(!!formData.with_ttc),
        is_overtime: Number(!!formData.is_overtime),
        is_payd: Number(!!formData.is_payd),
        client_id: formData.client?.id,
        since: getUnixTime(formData.since),
        distance: formData.distance,
        location: {
          ...formData.location,
          location: `${formData.location.location}--${formData.order_count}`,
        },
      },
      submitCallback: response => {
        submitFormRef(response)
        onSubmitForm(response)
      },
    })
  }

  const onKeyPress = e => {
    const keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()

      return false
    }
  }

  return (
    <form
      onKeyPress={onKeyPress}
      onSubmit={handleSubmitForm}
      className="inputForm modal"
    >
      <SearchAutocomplete
        label="Client"
        disabled={formData.particular || formData.from_cabinet}
        value={formData.client?.label}
        options={clientOptions}
        onChange={value => {
          const newClient = clientOptions.find(propEq('id', value))

          customHandleChange('client', newClient)
        }}
      />

      <div className="formInput">
        <label>Emplacement</label>
        <div className="locationInputWrap">
          <PlacesAutocomplete
            disabled={formData.particular || formData.from_cabinet}
            defaultValue={formData.location?.location}
            onInputChange={event => {
              customHandleChange('location', {
                location: event.target.value,
                location_id: event.target.value,
                location_lat: 0.00,
                location_lng: 0.00,
              })
            }}
            onChange={value => {
              getDistanceToClient(value, result => {
                customHandleChange('location', value)
                customHandleChange('distance', result.distance)
              })
            }}
          />

          <select
            value={formData.order_count}
            onChange={setFormData}
            name="order_count"
          >
            {orderCountLabels.map(count => (
              <option key={count.id} value={count.id}>
                {count.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <FormInput
        label="Chantier Téléphone"
        name="phone"
        type="tel"
        value={formData.phone}
        onChange={setFormData}
        placeholder="optional"
      />

      <FormInput
        name="phone_2"
        type="tel"
        value={formData.phone_2}
        onChange={setFormData}
        placeholder="optional"
      />

      <FormInput
        label="Commentaire"
        name="comments"
        value={formData.comments}
        onChange={setFormData}
        placeholder="optional"
      />

      <div className="formInput">
        <DatePicker
          inline
          locale="fr"
          selected={formData.since}
          onChange={value => customHandleChange('since', value)}
          showTimeSelect
          timeIntervals={60}
          timeCaption="l'heure"
          timeFormat="HH:mm"
          dateFormat="MMMM d, yyyy HH:mm"
        />
      </div>

      <div className="formInput checbox">
        include Heure{' '}
        <input
          type="checkbox"
          checked={formData.has_hour}
          onChange={({ target: { checked } }) =>
            customHandleChange('has_hour', checked)
          }
        />
      </div>

      <div className="formInput">
        <label htmlFor="benne_size">La taille de benne</label>
        <select
          value={formData.benne_size}
          onChange={setFormData}
          name="benne_size"
        >
          {sizeOptionsList.map(bennesize => (
            <option key={bennesize.id} value={bennesize.id}>
              {bennesize.label}
            </option>
          ))}
        </select>
      </div>

      <div className="formInput">
        <label htmlFor="trash_type">Type de mater</label>
        <select
          value={formData.trash_type}
          onChange={setFormData}
          name="trash_type"
        >
          {trashTypeOptions.map(trashType => (
            <option key={trashType.id} value={trashType.id}>
              {trashType.label}
            </option>
          ))}
        </select>
      </div>

      <div className="formInput">
        <label htmlFor="driver_id">Camion</label>
        <select
          value={formData.driver_id}
          onChange={setFormData}
          name="driver_id"
        >
          {driverOptions.map(driver => (
            <option key={driver.id} value={driver.id}>
              {driver.label}
            </option>
          ))}
        </select>
      </div>

      <FormInput
        min="0"
        label="Prix € HT"
        name="amount"
        type="number"
        value={formData.amount}
        onChange={setFormData}
        placeholder="optional"
        disabled={formData.from_cabinet}
      />

      <div className="formInput checbox">
        Include TTC
        <input
          type="checkbox"
          checked={formData.with_ttc}
          disabled={formData.from_cabinet}
          onChange={({ target: { checked } }) =>
            customHandleChange('with_ttc', Number(checked))
          }
        />
      </div>

      <div className="formInput checbox">
        Paye
        <input
          type="checkbox"
          checked={formData.is_payd}
          disabled={formData.from_cabinet}
          onChange={({ target: { checked } }) =>
            customHandleChange('is_payd', Number(checked))
          }
        />
      </div>

      <div className="formInput checbox">
        Travail supplémentaire
        <input
          type="checkbox"
          checked={formData.is_overtime}
          onChange={({ target: { checked } }) =>
            customHandleChange('is_overtime', Number(checked))
          }
        />
      </div>

      {!editMode && (
        <div className="formInput">
          <label htmlFor="status">Statut</label>
          <select value={formData.status} onChange={setFormData} name="status" disabled={formData.from_cabinet}>
            {statusOptionsList.map(status => (
              <option key={status.id} value={status.id}>
                {status.label}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="formFooter">
        {statusNode}
        <button type="submit" className="button" disabled={formIsFetching}>
          submit
        </button>
      </div>
    </form>
  )
}

ModalContent.defaultProps = {
  since: new Date(),
}

export default ModalContent
