import { all, takeEvery, call, put } from 'redux-saga/effects'
import authorizedApiRequest from '../../../common/fetch/authorized'
import appendToFormData from '../../../common/fetch/appendToFormData'

import {
  getDriversSucces,
  onAddNewDriverSucces,
  onRemoveDriverSucces,
  onUpdateDriverSucces,
} from '../../../actions/drivers'

import { DRIVERS } from '../../../actions/drivers/types'
import { formIsFetching } from '../../../actions'

export function* fetchDriversList() {
  const payloadResponse = yield call(authorizedApiRequest, '/drivers', {
    method: 'GET',
  })

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(getDriversSucces(serverResponse))
  }
}

function* addDriver({ payload: { formData, submitCallback } }) {
  yield put(formIsFetching(true))

  const payloadResponse = yield call(authorizedApiRequest, '/drivers', {
    method: 'POST',
    body: appendToFormData(new FormData(), formData),
  })

  yield put(formIsFetching(false))

  submitCallback(payloadResponse)

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(onAddNewDriverSucces(serverResponse))
  }
}

function* updateDriver({ payload: { id, label, submitCallback } }) {
  const payloadResponse = yield call(authorizedApiRequest, '/drivers', {
    method: 'POST',
    body: appendToFormData(new FormData(), { id, label, _method: 'PUT' }),
  })

  submitCallback(payloadResponse)

  if (payloadResponse.status === 204) {
    yield put(
      onUpdateDriverSucces({
        id,
        label,
      }),
    )
  }
}

function* removeDriver({ payload }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/drivers/${payload}`,
    {
      method: 'DELETE',
    },
  )

  if (payloadResponse.status === 200) {
    yield put(onRemoveDriverSucces(payload))
  }
}

export default function* driversSagaWatcher() {
  yield all([
    takeEvery(DRIVERS.REQUEST_ADD, addDriver),
    takeEvery(DRIVERS.REQUEST_REMOVE, removeDriver),
    takeEvery(DRIVERS.REQUEST_UPDATE, updateDriver),
  ])
}
