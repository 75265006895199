import classNames from 'classnames'
import React, { useState, memo } from 'react'

const ConfirmButton = ({ onClick, label, className, disabled }) => {
  const [confirmed, setConfirmed] = useState(false)

  const confirmAction = () =>
    setTimeout(() => {
      setConfirmed(true)
    }, 500)

  const cancelAction = () => setConfirmed(false)

  if (confirmed) {
    return (
      <div className="buttonsSet">
        <button className="button confirm" onClick={onClick}>
          confirm
        </button>
        <button className="button cancel" onClick={cancelAction}>
          anuler
        </button>
      </div>
    )
  }

  return (
    <button className={classNames("button", className)} onClick={confirmAction} disabled={disabled}>
      {label}
    </button>
  )
}

export default memo(ConfirmButton)
