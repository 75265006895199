import React from 'react'

import { Calendar, InvoiceGenerator, Tabs } from '../../common/components'
import EventsList from './components/EventsList'
import EventsFilter from './components/EventsFilter'
import NewEventModal from './components/NewEventModal'
import ChantiersList from './components/ChantiersList'
import DebtList from './components/DebtList'

const DescktopVersion = ({
  eventsCount,
  requestEventsList,
  chantiersList,
  clientsList,
  driversList,
  eventsList,
  activeTab,
  period,
  loadingMore,
  setPeriod,
  setFilterModel,
  filterModel,
  onSetActiveTab,
}) => (
  <div className="eventsDeshboard">
    <div className="deshboardHeader">
      <div className="periodLabel" />
      <div className="flexEnd">
        <EventsFilter
          setFilterModel={setFilterModel}
          driversList={driversList}
          activeTab={activeTab}
        />
        <NewEventModal
          period={period}
          driversList={driversList}
          clientsList={clientsList}
        />
      </div>
    </div>
    <div className="deshboard">
      <Tabs
        className="eventsListTab"
        onSetActiveTab={onSetActiveTab}
        items={[
          {
            id: 'activites',
            title: `Activites (${period?.label}: ${eventsCount.total})`,
            content: (
              <EventsList
                list={eventsList}
                eventsCount={eventsCount}
                loadingMore={loadingMore}
                driversList={driversList}
                clientsList={clientsList}
                requestEventsList={requestEventsList}
              />
            ),
          },
          {
            id: 'chantiers',
            title: `Depot Bennes (${chantiersList.length || ''})`,
            content: (
              <ChantiersList
                list={chantiersList}
                filterModel={filterModel}
                period={period}
              />
            ),
          },
          {
            id: 'dettes',
            title: `Liste des dettes`,
            content: (
              <DebtList
                clientsList={clientsList}
                filterModel={filterModel}
                period={period}
              />
            ),
          },
        ]}
      />
      <div className="rightSide">
        <Calendar defaultScale="months" onChangeDate={setPeriod} disabled={activeTab === 'chantiers'} />
        <InvoiceGenerator />
      </div>
    </div>
  </div>
)

export default DescktopVersion
