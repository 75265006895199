import classes from 'classnames'
import format from 'date-fns/format'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ConfirmButton from '../ConfirmButton'

const cipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

  return text => text.split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
}

const checkoutCipher = cipher(process.env.REACT_APP_SECRET)

const DebtClientItem = ({
  onEditButtonClick,
  onAddNewDebtClientPart,
  onRemoveDebtPart,
  onRemoveDebt,
  client,
  amount,
  id,
  parts,
  since,
  paidAmount,
  facture_number,
}) => {
  useEffect(() => {
    if (paidAmount > 0) {
      onAddNewDebtClientPart(
        {
          client,
          amount,
          debt_id: id,
          maxValue: amount - paidAmount,
        },
        true,
      )
    }
  }, [paidAmount])

  return (
    <div className={classes('deptItem', { paye: paidAmount === amount })}>
      <div className="details">
        <div className="client">
          {format(since * 1000, 'dd-MM-yyyy')}: {client.label}
        </div>
        <div className="list-controls">
          <button
            disabled={paidAmount === amount}
            className="button"
            onClick={() =>
              onAddNewDebtClientPart({
                client,
                debt_id: id,
                amount,
                maxValue: amount - paidAmount,
              })
            }
          >
            Nouveau dette part
          </button>
          <button className="button" onClick={() => onEditButtonClick(id)}>
            Editer
          </button>
          <ConfirmButton label="Supprimer" onClick={() => onRemoveDebt(id)} />
        </div>
      </div>
      <div className="details">
        <div className="client">Facture No: {facture_number}</div>
        <span className="totals">
          {paidAmount}/{amount}
        </span>
      </div>

      {!!parts.length && (
        <div className="details deptPartsList">
          {parts.map(debtPart => (
            <div className="deptPart" key={debtPart.id}>
              <div>
                <span>{format(debtPart.created_at * 1000, 'dd-MM-yyyy')}:</span>
                <span>{debtPart.amount}€</span>
              </div>
              <div>
                {
                  debtPart.with_card && (
                    <>
                      {
                        debtPart.checkout_id ? (
                          <span>paye</span>
                        ) : (
                          <Link
                            target='_blank'
                            to={`/checkout/${checkoutCipher(JSON.stringify({
                              debtId: id,
                              amount: debtPart.amount,
                              debtPartId: debtPart.id, 
                              facture_number,
                              checkoutId: null
                            }))}`}
                          >
                            Virement link
                          </Link>
                        )
                      }
                    </>
                  )
                }
                <ConfirmButton
                  label="x"
                  disabled={debtPart.checkout_id}
                  onClick={() => onRemoveDebtPart(id, debtPart.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DebtClientItem
