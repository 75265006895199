import React, { useLayoutEffect, useRef } from "react";

const PlacesAutocomplete = ({ onChange, ...props}) => {
  const inputRef = useRef(null)

  const onSelectPlace = (autocomplete) => () => {
    const place = autocomplete.getPlace();

    if (!place.place_id) {
      return;
    }

    onChange({
      location_id: place.place_id,
      location: place.formatted_address,
      location_lat: place.geometry.location.lat(lat => lat),
      location_lng: place.geometry.location.lng(lng => lng),
    })
  }

  useLayoutEffect(() => {
    if(inputRef.current) {
      const autocompleteInst = new window.google.maps.places.Autocomplete(inputRef.current, {
        componentRestrictions: {
          country: 'fr',
        },
        types: ['address'],
      })

      autocompleteInst.addListener("place_changed", onSelectPlace(autocompleteInst));
    } 
  }, [])

  return (
    <input
      type="text"
      ref={inputRef}
      id="autocomplete_input"
      disabled={props.disabled}
      onChange={props.onInputChange}
      className={props.className}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
    />
  )
}
export default PlacesAutocomplete;
