import { queryBuilder } from '../../../common/fetch'
import { config } from '../../../common/utils'
import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { getAuthUser } from '../../../selectors'
import authorizedApiRequest from '../../../common/fetch/authorized'
import appendToFormData from '../../../common/fetch/appendToFormData'

import {
  getEventsSucces,
  removeEventSucces,
  onAddNewEventSucces,
  updateEventSucces,
  updateEventStatusSucces,
  updateEventOwnerSucces,
  captureEventSuccess,
} from '../../../actions/events'

import { EVENTS } from '../../../actions/events/types'
import { formIsFetching } from '../../../actions'

function* fetchEventsList({
  payload: { submitCallback, shouldResetList, ...payload },
}) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/events${queryBuilder(payload)}`,
    {
      method: 'GET',
    },
  )

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield call(submitCallback, payloadResponse)
    yield put(getEventsSucces({ shouldResetList, response: serverResponse }))
  }
}

function* fetchEventInvoices({
  payload: { submitCallback, eventId },
}) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/events/invoices/${eventId}`,
    {
      method: 'GET',
    },
  )

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield call(submitCallback, serverResponse.list)
  }
}

export function* addEventInvoice({ payload: { eventId, file, submitCallback } }) {
  const payloadResponse = yield call(authorizedApiRequest, `/events/invoices/${eventId}`, {
    method: 'POST',
    body: appendToFormData(new FormData(), {
      file
    }),
  })

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield call(submitCallback, serverResponse)
  }
}

export function* getEventInvoice({ payload: { eventId, invoiceId, submitCallback } }) {
  const payloadResponse = yield call(authorizedApiRequest, `/events/get-invoices/${eventId}/${invoiceId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf'
    }
  })

  if (payloadResponse.status === 200) {
    const file = yield payloadResponse.blob()

    yield call(submitCallback, file)
  }
}

export function* requestRetrievableEventsSaga({ payload: { location, submitCallback } }) {
  const payloadResponse = yield call(authorizedApiRequest, `/events/retrievable-events?location=${location}`, {
    method: 'GET',
  })

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield call(submitCallback, serverResponse)
  }
}

export function* removeEventInvoice({ payload: { eventId, invoiceId, submitCallback } }) {
  const payloadResponse = yield call(authorizedApiRequest, `/events/invoices/${eventId}/${invoiceId}`, {
    method: 'DELETE',
  })

  if (payloadResponse.status === 200) {
    yield call(submitCallback, payloadResponse)
  }
}

function* updateEvent({ payload: { formData, submitCallback } }) {
  yield put(formIsFetching(true))

  const eventLocation = formData.location.location ? formData.location : {}

  const formattedFormData = {
    _method: 'PUT',
    ...formData,
    ...eventLocation,
    since: formData.since,
    driver_id: parseInt(formData.driver_id, 10) || null,
  }

  if(formData.from_cabinet) {
    const clientAppPayloadResponse = yield call(fetch, `${config.clientApiUrl}/event/remote-update`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        eventId: formData.ref_event,
        location_id: formattedFormData.location.location_id,
        location: formattedFormData.location.location,
        longitude: formattedFormData.location.location_lng,
        latitude: formattedFormData.location.location_lat,
        trash_type: formattedFormData.trash_type,
        benne_size: formattedFormData.benne_size,
        date: new Date(formattedFormData.since*1000),
      }),
    })
  
    if(clientAppPayloadResponse.status !== 200) {
      throw new Error('failed to update event')
    }
  }

  const payloadResponse = yield call(authorizedApiRequest, '/events', {
    method: 'POST',
    body: appendToFormData(new FormData(), formattedFormData),
  })

  yield put(formIsFetching(false))
  yield call(submitCallback, payloadResponse)

  if (payloadResponse.status === 204) {
    yield put(
      updateEventSucces({
        ...formData,
        ...eventLocation,
        since: formData.since,
      }),
    )
  }
}

export function* addEvent({ payload: { formData, submitCallback } }) {
  yield put(formIsFetching(true))
  let clientAppPayloadResponse = null

  if(formData.from_cabinet) {
    clientAppPayloadResponse = yield call(fetch, `${config.clientApiUrl}/event/remote-create`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email: formData.client.email,
        location_id: formData.location.location_id,
        location: formData.location.location.split('--')[0],
        longitude: formData.location.location_lng,
        latitude: formData.location.location_lat,
        trash_type: formData.trash_type,
        benne_size: formData.benne_size,
        date: new Date(formData.since*1000),
        status: Number(formData.status),
        retrieve_id: formData.retrievedEvent?.ref_event,
      }),
    })
  
    if(clientAppPayloadResponse.status !== 200) {
      throw new Error('failed to create event')
    }
  }

  const clientCreatedEvent = clientAppPayloadResponse ? yield clientAppPayloadResponse.json() : { id: null }

  const payloadResponse = yield call(authorizedApiRequest, '/events', {
    method: 'POST',
    body: appendToFormData(new FormData(), {
      ...formData,
      ref_event: clientCreatedEvent.id,
      retrieve_id: formData.retrievedEvent?.id
    }),
  })

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    if(formData.from_cabinet) {
      const refEventSetResponse = yield call(fetch, `${config.clientApiUrl}/event/remote-set-ref-id`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ref_event: serverResponse.id,
          eventId: clientCreatedEvent.id,
        }),
      })
  
      if(refEventSetResponse.status !== 200) {
        throw new Error('failed to set event ref id')
      }
    }

    yield put(
      onAddNewEventSucces({
        ...serverResponse,
        client: serverResponse.client || formData.client || null,
      }),
    )
  }

  yield put(formIsFetching(false))
  yield call(submitCallback, payloadResponse)
}

export function* captureEvent({ payload: { nextCaptureAmount, captureService, eventId, refEventId, submitCallback } }) {
  yield put(formIsFetching(true))

  const captureResponse = yield call(fetch, `${config.clientApiUrl}/event/remote-capture/${refEventId}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify({
      captureAmount: nextCaptureAmount,
      serviceId: captureService,
    }),
  })

  if(captureResponse.status === 200) {
    yield put(captureEventSuccess({ eventId, nextCaptureAmount }))
  }

  yield put(formIsFetching(false))
  yield call(submitCallback, captureResponse)
}

function* removeEvent({ payload }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/events/${payload.id}`,
    {
      method: 'DELETE',
    },
  )

  if (payloadResponse.status === 200) {
    yield put(removeEventSucces(payload))
  }
}

function* updateEventStatus({ payload: { eventId, status } }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/events/${eventId}/${status}`,
    {
      method: 'POST',
    },
  )

  if (payloadResponse.status === 200) {
    yield put(updateEventStatusSucces({ eventId, status }))
  }
}

function* updateEventOwner({ payload: { eventId } }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/events/set-owner/${eventId}`,
    {
      method: 'POST',
    },
  )

  const authUser = yield select(getAuthUser)

  if (payloadResponse.status === 200) {
    yield put(updateEventOwnerSucces({ eventId, user_id: authUser.id }))
  }
}

export default function* eventsSagaWatcher() {
  yield all([
    takeEvery(EVENTS.REQUEST_ADD, addEvent),
    takeEvery(EVENTS.REQUEST_CAPTURE, captureEvent),
    takeEvery(EVENTS.REQUEST_UPDATE, updateEvent),
    takeEvery(EVENTS.REQUEST_REMOVE, removeEvent),
    takeEvery(EVENTS.REQUEST_LOAD, fetchEventsList),
    takeEvery(EVENTS.REQUEST_INVOICE, getEventInvoice),
    takeEvery(EVENTS.REQUEST_INVOICE_REMOVE, removeEventInvoice),
    takeEvery(EVENTS.REQUEST_INVOICE_UPLOAD, addEventInvoice),
    takeEvery(EVENTS.REQUEST_INVOICES, fetchEventInvoices),
    takeEvery(EVENTS.REQUEST_UPDATE_OWNER, updateEventOwner),
    takeEvery(EVENTS.REQUEST_UPDATE_STATUS, updateEventStatus),
    takeEvery(EVENTS.REQUEST_RETRIEVABLE, requestRetrievableEventsSaga),
  ])
}
