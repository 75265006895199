import format from 'date-fns/format'
import { find, propEq } from 'ramda'
import React, { useLayoutEffect, useRef } from 'react'
import { depoCoords, sizeOptionsList, statusOptionsList } from '../../../../common/constants';
import mapStyle from './map_style.json'

const infoWindow = new window.google.maps.InfoWindow({
  content: "",
  disableAutoPan: true,
});

const addMarker = ({ client, status, driver, benne_size, since, location, location_lat, location_lng }, map) => {
  const marker = new window.google.maps.Marker({
    position: {
      lat: Number(location_lat),
      lng: Number(location_lng)
    },
    title: location
  });

  marker.setMap(map);

  const benneSize = find(propEq('id', benne_size), sizeOptionsList)
  const benneStatus = find(propEq('id', status), statusOptionsList)
  const formattedDate = format(since * 1000, 'dd.MM.yyyy')

  marker.addListener("click", () => {
    infoWindow.setContent(
      `<div class='markerInfoWindow'>
        <span>client: ${client.label}</span>
        <span>depot date: ${formattedDate}</span>
        <span>status: ${benneStatus.label}</span>
        <span>taille de benne: ${benneSize.label}</span>
        <span>camion: ${driver ? driver.label : ''}</span>
      </div>`
    );

    infoWindow.open(map, marker);
  });
}

const ItemsMap = ({ elements }) => {
  const mapContainerRef = useRef(null)

  useLayoutEffect(() => {
    const map = new window.google.maps.Map(mapContainerRef.current, {
      zoom: 10,
      center: depoCoords,
      styles: mapStyle
    })

    elements.forEach((chantier) => {
      addMarker(chantier, map)
    });
  }, [elements])

  return (
    <div className="chantiersListMap" ref={mapContainerRef} />
  )
}

export default ItemsMap
