import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormValidator } from '../../../../common/hooks'
import { onUpdateDriver } from '../../../../actions/drivers'

const EditDriverForm = ({ id, label, onCancel }) => {
  const [driverName, setDriverName] = useState(label)
  const { onSubmitForm, statusNode } = useFormValidator({
    succes: 'chauffeur a été ajouté avec succès!',
  })
  const dispatch = useDispatch()

  const handleUpdateDriver = () => {
    dispatch(
      onUpdateDriver({
        id,
        label: driverName,
        submitCallback: response => {
          onSubmitForm(response)

          if (response.status === 204) {
            setTimeout(onCancel, 2000)
          }
        },
      }),
    )
  }

  const handleChangeDriverName = e => {
    setDriverName(e.target.value)
  }

  return (
    <div className="inputForm editFormItem">
      <div className="formInput">
        <label>Camion</label>
        <input
          value={driverName}
          onChange={handleChangeDriverName}
          autoComplete="off"
        />
      </div>

      <div className="formFooter">
        {statusNode}
        <button onClick={handleUpdateDriver} type="button">
          update
        </button>
        <button onClick={onCancel} type="button">
          anuler
        </button>
      </div>
    </div>
  )
}

export default EditDriverForm
