import { filter, findIndex, propEq, update } from 'ramda'
import { EVENTS } from '../../actions/events/types'

const initialState = {
  list: [],
  amount: 0,
  cashAmount: 0,
  eventsCount: {
    total: 0,
    loaded: 0,
  },
}

const getEvents = (state, { payload: { shouldResetList, response } }) => ({
  list: shouldResetList ? response.list : state.list.concat(response.list),
  cashAmount: parseInt(response.total_amount_cash, 10),
  amount: parseInt(response.total_amount, 10),
  eventsCount: {
    total: response.total_items_count,
    loaded: shouldResetList
      ? response.list.length
      : state.list.length + response.list.length,
  },
})

const addEvent = (state, action) => ({
  list: [action.payload, ...state.list],
  amount: action.payload.amount && action.payload.with_ttc ? state.amount + action.payload.amount : state.amount,
  cashAmount: action.payload.amount && !action.payload.with_ttc ? state.cashAmount + action.payload.amount : state.cashAmount,
  eventsCount: {
    total: state.eventsCount.total + 1,
    loaded: state.list.length + 1,
  },
})

const removeEvent = (state, action) => {
  const nextEventAmount = action.payload.amount
    ? parseInt(action.payload.amount, 10)
    : 0

  return {
    list: filter(event => event.id !== action.payload.id, state.list),
    amount: action.payload.with_ttc? state.amount - nextEventAmount : state.amount,
    cashAmount: !action.payload.with_ttc ? state.cashAmount - action.payload.amount : state.cashAmount,
    eventsCount: {
      total: state.eventsCount.total - 1,
      loaded: state.list.length - 1,
    },
  }
}

const updateEvent = (state, action) => {
  const eventIndex = findIndex(propEq('id', action.payload.id), state.list)
  const nextEventAmount = action.payload.amount ? parseInt(action.payload.amount, 10) : 0

  const nextAmount = state.list[eventIndex].amount
    ? state.amount - state.list[eventIndex].amount + nextEventAmount
    : state.amount + nextEventAmount

  const nextCashAmount = state.list[eventIndex].amount
    ? state.cashAmount - state.list[eventIndex].amount + nextEventAmount
    : state.cashAmount + nextEventAmount

  return {
    ...state,
    list: update(eventIndex, action.payload, state.list),
    cashAmount: !action.payload.with_ttc ? nextCashAmount : state.cashAmount,
    amount: action.payload.with_ttc ? nextAmount : state.amount,
  }
}

const updateEventStatus = (state, { payload: { eventId, status } }) => {
  const eventIndex = findIndex(propEq('id', eventId), state.list)

  if (eventIndex !== -1) {
    return {
      ...state,
      list: update(
        eventIndex,
        {
          ...state.list[eventIndex],
          status,
        },
        state.list,
      ),
    }
  }

  return state
}

const updateEventOwner = (state, { payload: { eventId, user_id } }) => {
  const eventIndex = findIndex(propEq('id', eventId), state.list)

  return {
    ...state,
    list: update(
      eventIndex,
      {
        ...state.list[eventIndex],
        user_id,
      },
      state.list,
    ),
  }
}

const updateEventCaptureAmount = (state, { payload: { eventId, nextCaptureAmount } }) => {
  const eventIndex = findIndex(propEq('id', eventId), state.list)

  return {
    ...state,
    list: update(
      eventIndex,
      {
        ...state.list[eventIndex],
        capture_amount: Number(nextCaptureAmount) + state.list[eventIndex].capture_amount,
      },
      state.list,
    ),
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case EVENTS.GET_LIST:
      return getEvents(state, action)
    case EVENTS.ADD:
      return addEvent(state, action)
    case EVENTS.REMOVE:
      return removeEvent(state, action)
    case EVENTS.UPDATE:
      return updateEvent(state, action)
    case EVENTS.UPDATE_STATUS:
      return updateEventStatus(state, action)
    case EVENTS.UPDATE_OWNER:
      return updateEventOwner(state, action)
    case EVENTS.CAPTURE_SUCCESS:
      return updateEventCaptureAmount(state, action)
    default:
      return state
  }
}

export default reducer
