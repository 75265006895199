import React, { memo, useEffect } from 'react'
import propTypes from 'prop-types'
import {
  format,
  startOfDay,
  startOfMonth,
  startOfYear,
  startOfWeek,
  endOfWeek,
  endOfDay,
  endOfMonth,
  endOfYear,
} from 'date-fns'
import { MonthsList, DatesList } from './components'
import { scale_types } from './constants'
import useCalendar from './useCalendar'

import frLocale from 'date-fns/locale/fr'
import './index.scss'

const getInterval = (scale, moment) => {
  if (scale === 'years') {
    return {
      since: startOfYear(moment),
      until: endOfYear(moment),
    }
  }

  if (scale === 'months') {
    return {
      since: startOfMonth(moment),
      until: endOfMonth(moment),
    }
  }

  if (scale === 'weeks') {
    return {
      since: startOfWeek(moment, { weekStartsOn: 1 }),
      until: endOfWeek(moment, { weekStartsOn: 1 }),
    }
  }

  return {
    since: startOfDay(moment),
    until: endOfDay(moment),
  }
}

const formatCallbackRs = (scale, currentMoment) => {
  if (scale === 'weeks') {
    const nextInterval = getInterval(scale, currentMoment)
    const sinceLabel = format(nextInterval.since, 'd MMMM, yyyy', {
      locale: frLocale,
    })

    const untilLabel = format(nextInterval.until, 'd MMMM, yyyy', {
      locale: frLocale,
    })

    return {
      scale,
      interval: nextInterval,
      label: `${sinceLabel} - ${untilLabel}`,
    }
  }

  const formatString =
    scale === 'years' ? 'yyyy' : (scale === 'months' && 'MMMM yyyy') || 'd MMMM, yyyy'

  return {
    scale,
    interval: getInterval(scale, currentMoment),
    label: format(currentMoment, formatString, {
      locale: frLocale,
    }),
  }
}

const Calendar = ({ defaultScale, defaultDate, onChangeDate, label, disabled }) => {
  const checkedDefaultscale = scale_types[defaultScale] || scale_types.years

  const {
    navLabel,
    onDesc,
    onAsc,
    scale,
    weekDays,
    monthNames,
    onSetScale,
    currentMoment,
  } = useCalendar(defaultDate, checkedDefaultscale)

  useEffect(() => {
    onChangeDate(formatCallbackRs(scale, currentMoment))
  }, [currentMoment, onChangeDate, scale])

  const onClickLabel = () => {
    const { days, months, years } = scale_types
    const newScale = scale === days ? months : years

    onSetScale(newScale)()
  }

  const onSetDate = (date, isCheckedDate) => {
    if (isCheckedDate) {
      onSetScale('months')()
    } else {
      onSetScale('days')(date)
    }
  }

  const onSetWeek = date => {
    onSetScale('weeks')(date)
  }

  return (
    <div className="calendar">
      {
        disabled && (
          <div className='disabled-area' />
        )
      }
      <div className="header">
        <div className="top">
          <div className="topLabel">
            <div className="label">{label}</div>
          </div>
          <div className="navigation">
            <button className="icon nav_prev" onClick={onDesc} />
            <div className="label" onClick={onClickLabel}>
              {navLabel}
            </div>
            <button className="icon nav_next" onClick={onAsc} />
          </div>
        </div>
      </div>
      {(scale === scale_types.years && (
        <MonthsList onSetScale={onSetScale} monthNames={monthNames} />
      )) || (
        <DatesList
          weekDays={weekDays}
          onSetDate={onSetDate}
          onSetWeek={onSetWeek}
          scale={scale}
          currentMoment={currentMoment}
        />
      )}
    </div>
  )
}

Calendar.defaultProps = {
  label: '',
  defaultScale: scale_types.years,
  defaultDate: new Date(),
}

Calendar.propTypes = {
  label: propTypes.string,
  defaultScale: propTypes.string,
  onChangeDate: propTypes.func.isRequired,
  defaultDate: propTypes.instanceOf(Date),
}

export default memo(Calendar)
