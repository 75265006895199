import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getUnixTime from 'date-fns/getUnixTime'

import { getDebtsList } from '../../../../selectors'
import { Popup } from '../../../../common/components'
import {
  requestUserDebts,
  requestRemoveDebt,
  requestRemoveDebtPart,
} from '../../../../actions/debts'

import './style.scss'

import DeptClientItem from './ClientItem'
import ClientForm from './ClientForm'
import DebtPartForm from './DebtPartForm'

const DebtList = ({ clientsList, filterModel: { debts_paid_status, search}, period }) => {
  const dispatch = useDispatch()
  const debtsList = useSelector(getDebtsList)

  const [filteredDebtsList, setFilteredDebtsList] = useState(debtsList)
  const [showDebtClientModal, setShowDebtClientModal] = useState(false)
  const [showDebtClientPartModal, setShowDebtClientPartModal] = useState(false)
  const [newDebtPartClient, setNewDebtPartClient] = useState(null)
  const [debtFormData, setDebtFormData] = useState()

  useEffect(() => {
    dispatch(
      requestUserDebts({
        search,
        since: getUnixTime(period.interval.since),
        until: getUnixTime(period.interval.until),
      }),
    )
  }, [search, period])

  useEffect(() => {
    if(debts_paid_status) {
      if(debts_paid_status === '1') {
        setFilteredDebtsList(debtsList.filter(({ paidAmount, amount }) => paidAmount === amount))
      }
  
      if(debts_paid_status === '2') {
        setFilteredDebtsList(debtsList.filter(({ paidAmount, amount }) => paidAmount !== amount))
      }
    } else {
      setFilteredDebtsList(debtsList)
    }

  }, [debts_paid_status, debtsList])

  const onAddNewDebtClient = () => {
    setShowDebtClientModal(true)
  }

  const onAddNewDebtClientPart = (client, isUpdate) => {
    if (!isUpdate) {
      setShowDebtClientPartModal(true)
    }
    setNewDebtPartClient(client)
  }

  const onRemoveDebt = debtId => {
    dispatch(requestRemoveDebt(debtId))
  }

  const onRemoveDebtPart = (debtId, debtPartId) => {
    dispatch(requestRemoveDebtPart(debtId, debtPartId))
  }

  const onEditButtonClick = debtId => {
    const debtToUpdate = filteredDebtsList.find(debt => debt.id === debtId)

    if (debtToUpdate) {
      setShowDebtClientModal(true)
      setDebtFormData({
        ...debtToUpdate,
        since: new Date(debtToUpdate.since * 1000),
      })
    }
  }

  return (
    <div className="debtList">
      <div className="list-controls">
        <button className="button" onClick={onAddNewDebtClient}>
          Noveau Client
        </button>
      </div>
      {filteredDebtsList.map(debt => (
        <DeptClientItem
          onEditButtonClick={onEditButtonClick}
          onAddNewDebtClientPart={onAddNewDebtClientPart}
          onRemoveDebtPart={onRemoveDebtPart}
          onRemoveDebt={onRemoveDebt}
          key={debt.id}
          {...debt}
        />
      ))}

      {showDebtClientModal && (
        <Popup
          compact
          onClose={() => {
            setShowDebtClientModal(false)
          }}
        >
          <ClientForm
            clientsList={clientsList}
            formData={debtFormData}
            editMode={!!debtFormData}
          />
        </Popup>
      )}

      {showDebtClientPartModal && (
        <Popup
          compact
          onClose={() => {
            setShowDebtClientPartModal(false)
            setNewDebtPartClient(null)
          }}
        >
          <DebtPartForm {...newDebtPartClient} />
        </Popup>
      )}
    </div>
  )
}

export default DebtList
