import React, { useState, useMemo, memo } from 'react'
import classes from 'classnames'
import { propEq } from 'ramda'
import { statusOptionsList } from '../../../../common/constants'

const MultiToggle = ({ onClick, value, disabled }) => {
  const [confirmed, setConfirmed] = useState(false)

  const cancelAction = () => {
    setConfirmed(false)
  }

  const confirmAction = () => {
    if (!disabled) {
      setTimeout(() => {
        setConfirmed(true)
      }, 300)
    }
  }

  const nextState = useMemo(() => {
    switch (value) {
      case '0':
        return '1'
      case '1':
        return '2'
      case '2':
        return '3'
      case '3':
        return '4'
      default:
        return '0'
    }
  }, [value])

  const toggleState = () => {
    setTimeout(() => {
      onClick(nextState)
      setConfirmed(false)
    }, 100)
  }

  const togglestatus = statusOptionsList.find(propEq('id', value))

  if (confirmed) {
    return (
      <div className="buttonsSet">
        <button className="button confirm" onClick={toggleState}>
          confirm
        </button>
        <button className="button cancel" onClick={cancelAction}>
          anuler
        </button>
      </div>
    )
  }

  return (
    <button
      className={classes('button statusChange', {
        warn: value === '1',
        succes: value === '2',
        warn_secondary: value === '3',
        pansante_status: value === '0',
        annulee_status: value === '4',
      })}
      onClick={confirmAction}
    >
      {togglestatus.label}
    </button>
  )
}

MultiToggle.defaultProps = {
  onClick: () => {},
}

export default memo(MultiToggle)
