import React from 'react'
import propTypes from 'prop-types'

import { scale_types } from '../constants'

const DatesList = ({ onSetScale, monthNames }) => {
  const handleClick = onSetScale(scale_types.months)

  return (
    <div className="body monthsList">
      {monthNames.map((month, index) => (
        <div
          key={index}
          className="monthItem"
          onClick={() => handleClick(index)}
        >
          {month}
        </div>
      ))}
    </div>
  )
}

DatesList.propTypes = {
  monthNames: propTypes.arrayOf(propTypes.string).isRequired,
  onSetScale: propTypes.func.isRequired,
}

export default DatesList
