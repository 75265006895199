import React, { useState, Fragment, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import {
  trashTypeOptionsList,
  statusOptionsList,
  sizeOptionsList,
  paidStatusOptions,
  debtsStatusOptions,
  defaultSelectOption
} from '../../../../common/constants'
import { useDebounce } from '../../../../common/hooks'
import { getDebtsAmount, getEventsAmount, getEventsCashAmount } from '../../../../selectors'

const EventsFilter = ({
  setFilterModel,
  driversList,
  activeTab,
}) => {
  const driversFilterOptions = [defaultSelectOption, ...driversList]
  const [statusFilter, setStatusFilter] = useState(statusOptionsList[0].id)
  const [sizeFilter, setSizefilter] = useState(sizeOptionsList[0].id)
  const [paidStatus, setPaidStatus] = useState(paidStatusOptions[0].id)
  const [driverFilter, setDriverFilter] = useState(driversFilterOptions[0].id)
  const [debtsPaidStatus, setDebtsPaidStatus] = useState(debtsStatusOptions[0].id)
  const [trashType, setTrashType] = useState(trashTypeOptionsList[0].id)

  const [searchQuery, setSearchQuery] = useState('')

  const debouncedSearchQuery = useDebounce(searchQuery, 500)

  const debtsAmount = useSelector(getDebtsAmount(debtsPaidStatus))
  const eventsTotalCashAmount = useSelector(getEventsCashAmount)
  const eventsTotalAmount = useSelector(getEventsAmount)

  const onSetStatusFilter = e => {
    setStatusFilter(e.target.value)
  }

  const onSetSizeFilter = e => {
    setSizefilter(e.target.value)
  }

  const onSetInputfilter = e => {
    setSearchQuery(e.target.value)
  }

  const onSetPaidStatusFilter = e => {
    setPaidStatus(e.target.value)
  }

  const onSetDebtsPaidStatusFilter = e => {
    setDebtsPaidStatus(e.target.value)
  }

  const onSetDriverFilter = e => {
    setDriverFilter(e.target.value)
  }

  const onSetTrashTypeFilter = e => {
    setTrashType(e.target.value)
  }

  useEffect(() => {
    setFilterModel({
      status: statusFilter,
      size: sizeFilter,
      search: debouncedSearchQuery,
      paid_status: paidStatus,
      driver_id: driverFilter,
      trash_type: trashType,
      debts_paid_status: debtsPaidStatus
    })
  }, [
    debouncedSearchQuery,
    debtsPaidStatus,
    paidStatus,
    trashType,
    statusFilter,
    sizeFilter,
    driverFilter,
    setFilterModel,
  ])

  const totals = activeTab === 'activites'? eventsTotalAmount : debtsAmount

  return (
    <Fragment>
      {activeTab === 'activites' && (
        <div className="inputContainer">
          <strong>{eventsTotalCashAmount}€ CASH</strong> | {`${totals}€ HT (${Math.ceil(totals * 1.20)}€ TTC)`}
        </div>
      )}

      {activeTab === 'dettes' && (
        <div className="inputContainer">
          <strong>{totals.unpaid}€ TTC Impayé</strong> | {`${Math.ceil(totals.total * 0.8)}€ HT (${totals.total}€ TTC)`}
        </div>
      )}

      <input
        type="text"
        placeholder="chercher..."
        value={searchQuery}
        onChange={onSetInputfilter}
      />

      {activeTab === 'dettes' && (
        <select onChange={onSetDebtsPaidStatusFilter} value={debtsPaidStatus}>
          {debtsStatusOptions.map((filter, index) => (
            <option value={filter.id} key={index}>
              {filter.label}
            </option>
          ))}
        </select>
      )}

      {activeTab === 'activites' && (
        <select onChange={onSetStatusFilter} value={statusFilter}>
          {statusOptionsList.map((filter, index) => (
            <option value={filter.id} key={index}>
              {filter.label}
            </option>
          ))}
        </select>
      )}

      {(activeTab === 'activites' || activeTab === 'chantiers') && (
        <select onChange={onSetSizeFilter} value={sizeFilter}>
          {sizeOptionsList.map((filter, index) => (
            <option value={filter.id} key={index}>
              {filter.label}
            </option>
          ))}
        </select>
      )}

      {(activeTab === 'activites' || activeTab === 'chantiers') && (
        <select onChange={onSetTrashTypeFilter} value={trashType}>
          {trashTypeOptionsList.map((filter, index) => (
            <option value={filter.id} key={index}>
              {filter.label}
            </option>
          ))}
        </select>
      )}

      {(activeTab === 'activites' || activeTab === 'chantiers') && (
        <select onChange={onSetDriverFilter} value={driverFilter}>
          {driversFilterOptions.map((filter, index) => (
            <option value={filter.id} key={index}>
              {filter.label}
            </option>
          ))}
        </select>
      )}

      {activeTab === 'activites' && (
        <select onChange={onSetPaidStatusFilter} value={paidStatus}>
          {paidStatusOptions.map((filter, index) => (
            <option value={filter.id} key={index}>
              {filter.label}
            </option>
          ))}
        </select>
      )}
    </Fragment>
  )
}

export default memo(EventsFilter)
