import React, { useState } from 'react'
import classes from 'classnames'
import './style.scss'

const Tabs = ({ items, className, onSetActiveTab }) => {
  const [activeItemIndex, onActiveIndexChange] = useState(0)

  return (
    <div className={classes('container tabs', { [className]: !!className })}>
      <div className="navigation">
        {items.map((tab, i) => (
          <button
            key={i}
            className={classes('tab', {
              active: i === activeItemIndex,
            })}
            onClick={() => {
              onSetActiveTab(items[i].id)
              onActiveIndexChange(i)
            }}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="tabsContent">
        {items[activeItemIndex] && items[activeItemIndex].content}
      </div>
    </div>
  )
}

Tabs.defaultProps = {
  onSetActiveTab: () => {},
  items: []
}

export default Tabs
