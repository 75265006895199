import React from 'react'
import { Calendar } from '../../common/components'
import EventsList from './components/EventsList'
import EventsFilter from './components/EventsFilter'
import NewEventModal from './components/NewEventModal'

const MobileVersion = ({
  eventsCount,
  requestEventsList,
  clientsList,
  driversList,
  eventsList,
  period,
  loadingMore,
  setPeriod,
  setFilterModel,
}) => (
  <div className="eventsDeshboard mobile">
    <div className="deshboardHeader">
      <div className="periodLabel">{`${period?.label}: ${eventsCount.total} activities`}</div>
      <div className="flexEnd">
        <EventsFilter
          mobileVersion
          setFilterModel={setFilterModel}
          driversList={driversList}
        />
        <NewEventModal
          period={period}
          driversList={driversList}
          clientsList={clientsList}
        />
      </div>
    </div>
    <div className="deshboard">
      <Calendar defaultScale="months" onChangeDate={setPeriod} />

      <EventsList
        list={eventsList}
        eventsCount={eventsCount}
        loadingMore={loadingMore}
        driversList={driversList}
        clientsList={clientsList}
        requestEventsList={requestEventsList}
      />
    </div>
  </div>
)

export default MobileVersion
