import React, { useMemo } from 'react'
import { splitEvery } from 'ramda'
import propTypes from 'prop-types'
import classes from 'classnames'
import { getDate } from 'date-fns'

const DatesList = ({
  currentMoment,
  weekDays,
  onSetDate,
  onSetWeek,
  scale,
}) => {
  const checkedDate = getDate(currentMoment)
  const weeksList = useMemo(() => splitEvery(7, weekDays), [weekDays])

  return (
    <div className="body datesList">
      {weeksList.map((week, i) => (
        <div
          className={classes('weekItem', {
            checked:
              checkedDate === week[0].date &&
              scale === 'weeks' &&
              week[0].isCurrentMonth,
          })}
          key={i}
        >
          {!!week[0].isCurrentMonth && (
            <div
              className="weekChooser"
              onClick={() => onSetWeek(week[0].date)}
            />
          )}

          {week.map(({ isCurrentMonth, date }, index) => {
            const dateIsChecked =
              checkedDate === date && isCurrentMonth && scale === 'days'

            return (
              <div
                key={index}
                className={classes('dateItem', { checked: dateIsChecked })}
              >
                {isCurrentMonth && (
                  <div
                    className="contentWrapper"
                    onClick={() => onSetDate(date, dateIsChecked)}
                  >
                    <div className="date">{date}</div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

DatesList.propTypes = {
  currentMoment: propTypes.instanceOf(Date),
  onSetDate: propTypes.func.isRequired,
  scale: propTypes.string.isRequired,
  weekDays: propTypes.arrayOf(
    propTypes.shape({
      date: propTypes.number,
      dayMoment: propTypes.instanceOf(Object),
      isCurrentMonth: propTypes.bool,
    }),
  ).isRequired,
}

export default DatesList
