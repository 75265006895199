import { CHANTIERS } from './types'

export const requestUserChantiers = payload => ({
  type: CHANTIERS.REQUEST_LIST,
  payload,
})

export const getChantiersSuccess = payload => ({
  type: CHANTIERS.GET_LIST,
  payload,
})
