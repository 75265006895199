import { DRIVERS } from './types'

export const onAddNewDriver = payload => ({
  type: DRIVERS.REQUEST_ADD,
  payload,
})

export const onAddNewDriverSucces = payload => ({
  type: DRIVERS.ADD,
  payload,
})

export const getDriversSucces = payload => ({
  type: DRIVERS.GET_LIST,
  payload,
})

export const onRemoveDriver = payload => ({
  type: DRIVERS.REQUEST_REMOVE,
  payload,
})

export const onRemoveDriverSucces = payload => ({
  type: DRIVERS.REMOVE,
  payload,
})

export const onUpdateDriver = payload => ({
  type: DRIVERS.REQUEST_UPDATE,
  payload,
})

export const onUpdateDriverSucces = payload => ({
  type: DRIVERS.UPDATE,
  payload,
})
