import { call, put } from 'redux-saga/effects'

// eslint-disable-next-line import/no-cycle
import { refreshAccessToken } from '../../saga/sagas/auth'

import fetchApiRequest from '.'

function* fetchAuthorizedApiRequest(url, options = {}) {
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
  }

  const payload = yield call(fetchApiRequest, url, {
    ...options,
    headers,
  })

  switch (payload.status) {
    case 401:
      try {
        yield call(refreshAccessToken)

        const retryPayload = yield call(fetchApiRequest, url, {
          ...options,
          headers,
        })

        return retryPayload
      } catch (e) {
        yield put('LOGOUT_REQUIEST')
      }

      break
    default:
      return payload
  }

  return null
}

export default fetchAuthorizedApiRequest
