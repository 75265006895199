import { queryBuilder } from '../../../common/fetch'
import { call, put, all, takeEvery } from 'redux-saga/effects'
import authorizedApiRequest from '../../../common/fetch/authorized'
import { getChantiersSuccess } from '../../../actions/chantiers'
import { CHANTIERS } from '../../../actions/chantiers/types'

function* fetchChantiersList({ payload }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/events/chantiers${queryBuilder(payload)}`,
    {
      method: 'GET',
    },
  )

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(getChantiersSuccess(serverResponse))
  }
}

export default function* chantiersSagaWatcher() {
  yield all([takeEvery(CHANTIERS.REQUEST_LIST, fetchChantiersList)])
}
