import frLocale from 'date-fns/locale/fr'
import { useState, useMemo } from 'react'
import {
  add,
  sub,
  set,
  format,
  getDaysInMonth,
  startOfMonth,
  getDay,
} from 'date-fns'

import { scale_types } from './constants'

const generateArray = length => new Array(length).fill(null)

const getMonthNames = () => {
  return generateArray(12).map((_, index) =>
    frLocale.localize.month(index, { width: 'abbreviated' }),
  )
}

const getDateFormat = scale => (scale === scale_types.years ? 'yyyy' : 'MMMM yyyy')

const getWeekDays = currentMonth => {
  const prevMonth = sub(currentMonth, { months: 1 })
  const firstDayInMonth = getDay(startOfMonth(currentMonth))
  const startOfMonthDay = firstDayInMonth !== 0 ? firstDayInMonth - 1 : 6
  const monthDaysNr = getDaysInMonth(currentMonth)

  return generateArray(42).map((_, index) => {
    const incIndex = index + 1
    const prevMonthDate =
      getDaysInMonth(prevMonth) - (startOfMonthDay - incIndex)
    const nextMonthDate = incIndex - (monthDaysNr + startOfMonthDay)

    const isCurrentMonth =
      incIndex > startOfMonthDay && index - startOfMonthDay < monthDaysNr

    const date =
      incIndex < startOfMonthDay
        ? prevMonthDate
        : (incIndex - startOfMonthDay > monthDaysNr && nextMonthDate) ||
          incIndex - startOfMonthDay

    return {
      date,
      isCurrentMonth,
    }
  })
}

const useCalendar = (defaultDate, defaultScale) => {
  const [currentMoment, setCurrentMoment] = useState(defaultDate)
  const [scale, setScale] = useState(defaultScale)

  const monthNames = getMonthNames()
  const navLabelFormat = getDateFormat(scale)

  const navLabel = format(currentMoment, navLabelFormat, {
    locale: frLocale,
  })

  const weekDays = useMemo(() => {
    return getWeekDays(currentMoment)
  }, [currentMoment])

  const onDesc = () => {
    setCurrentMoment(sub(currentMoment, { [scale]: 1 }))
  }

  const onAsc = () => {
    setCurrentMoment(add(currentMoment, { [scale]: 1 }))
  }

  const onSetScale = type => index => {
    setScale(type)

    if (type === scale_types.months && index > -1) {
      setCurrentMoment(set(currentMoment, { month: index }))
    }

    if (
      (type === scale_types.days || type === scale_types.weeks) &&
      index > -1
    ) {
      setCurrentMoment(set(currentMoment, { date: index }))
    }
  }

  return {
    onDesc,
    onAsc,
    onSetScale,
    currentMoment,
    navLabel,
    weekDays,
    monthNames,
    scale,
  }
}

export default useCalendar
