import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Popup } from '../../../../common/components'
import { onAddNewEvent } from '../../../../actions/events'
import { onAddNewDriver, onRemoveDriver } from '../../../../actions/drivers'
import { onAddNewClient, onRemoveClient } from '../../../../actions/clients'
import { formIsFetchingStatus } from '../../../../selectors'

import EventModal from './ModalContent'
import NewClientModal from './NewClientModal'
import NewDriverModal from './NewDriverModal'
import set from 'date-fns/set'

const NewEventModal = ({ period, driversList, clientsList }) => {
  const [showModal, setShowmodal] = useState(false)
  const [modalKind, setModalkind] = useState('event')
  const formIsFetching = useSelector(formIsFetchingStatus)

  const dispatch = useDispatch()

  const toggleModal = kind => {
    setModalkind(kind)
    setShowmodal(!showModal)
  }

  const onAddEvent = formData => {
    dispatch(onAddNewEvent(formData))
  }

  const onAddDriver = formData => {
    dispatch(onAddNewDriver(formData))
  }

  const handleRemoveDriver = driverId => {
    dispatch(onRemoveDriver(driverId))
  }

  const handleRemoveclient = clientid => {
    dispatch(onRemoveClient(clientid))
  }

  const onAddClient = formData => {
    dispatch(onAddNewClient(formData))
  }

  const getModalContent = () => {
    switch (modalKind) {
      case 'event':
        return (
          <EventModal
            onSubmit={onAddEvent}
            driversList={driversList}
            clientsList={clientsList}
            formIsFetching={formIsFetching}
            defaultformData={{
              order_count: 1,
              since:
                period.scale !== 'days'
                  ? set(new Date(), { hours: 0, minutes: 0 })
                  : set(period.date, { hours: 0, minutes: 0 }),
            }}
          />
        )

      case 'driver':
        return (
          <NewDriverModal
            onSubmit={onAddDriver}
            onRemoveDriver={handleRemoveDriver}
            driversList={driversList}
            formIsFetching={formIsFetching}
          />
        )

      default:
        return (
          <NewClientModal
            onSubmit={onAddClient}
            onRemoveClient={handleRemoveclient}
            clientsList={clientsList}
            formIsFetching={formIsFetching}
          />
        )
    }
  }

  return (
    <>
      <button onClick={() => toggleModal('event')} className="button">
        Nouvelle Activité
      </button>
      <button onClick={() => toggleModal('driver')} className="button">
        Camion
      </button>
      <button onClick={() => toggleModal('client')} className="button">
        Client
      </button>
      {showModal && (
        <Popup onClose={() => toggleModal()} compact>
          {getModalContent()}
        </Popup>
      )}
    </>
  )
}

export default NewEventModal
