import { LOGIN, LOGOUT, AUTH_STATE, PROFILE, TOKEN } from './types'

/**
 * Request auth state
 *
 * airst redux action which application is calling
 * receives current sesion user auth state
 *
 * @return {object} an object which represents redux action
 */

export const requestFetchInItialState = () => ({
  type: AUTH_STATE.REQUEST,
})

/**
 * Request auth state succes
 *
 * action which is called when initial state request ends succesfully
 *
 * @return {object} an object which represents redux action
 */

export const fetchInitialStateSucces = () => ({
  type: AUTH_STATE.SUCCESS,
})

/**
 * Request auth state fail
 *
 * action which is called if initial state request fails
 * usually this cation informs that something is wrong with backend
 *
 * @return {object} an object which represents redux action
 */

export const fetchInitialStateFail = () => ({
  type: AUTH_STATE.FAILURE,
})

/**
 * LogIn submit
 *
 * action which is callled when user submit login form
 *
 * @param {string} email      user email
 * @param {string} password   user password
 *
 * @return {object} an object which represents redux action
 */

export const requestLogin = ({ email, password }) => ({
  type: LOGIN.REQUEST,
  payload: { email, password },
})

export const loginSucces = () => ({
  type: LOGIN.SUCCESS,
})

/**
 * Login fail
 *
 * action which is called when user receiving error
 * response from backend (invalid password, non-existent user...)
 *
 * @param {object} errors   details about error received as response from backend
 *
 * @return {object} an object which represents redux action
 */

export const loginFail = errors => ({
  type: LOGIN.FAILURE,
  payload: errors,
})

/**
 * Logout current user request
 *
 * action which is called when current user receives to be logged out
 *
 * @return {object} an object which represents redux action
 */

export const requrstLogout = () => ({
  type: LOGOUT.REQUEST,
})

/**
 * Logout current user
 *
 * action which is called when current auth user is logged out
 * and their token has invalidated from backend side
 *
 * @return {object} an object which represents redux action
 */

export const logoutSucces = () => ({
  type: LOGOUT.SUCCESS,
})

/**
 * Succes Refresh Token
 *
 * action which is called when user ceceiving
 * succes refresh token response from backend
 *
 * @param {string} access_token   new auth user token
 * @param {number} expires_in    new auth user token validity in seconds
 *
 * @return {object} an object which represents redux action
 */

export const refreshTokenSucces = payload => ({
  type: TOKEN.REFRESH,
  payload,
})

/**
 * Set auth user details
 *
 * action which is called to set current auth user details
 *
 * @param {object} profileDetails   auth user details
 *
 * @return {object} an object which represents redux action
 */

export const setProfileData = profileDetails => ({
  type: PROFILE.SET_DETAILS,
  payload: profileDetails,
})
