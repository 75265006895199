import { combineReducers } from 'redux'
import { pick } from 'ramda'

import { LOGOUT } from '../actions/auth/types'

import user from './reducers/user'
import auth from './reducers/auth'
import app from './reducers/appState'
import drivers from './reducers/drivers'
import events from './reducers/events'
import clients from './reducers/clients'
import payments from './reducers/payments'
import chantiers from './reducers/chantiers'
import debts from './reducers/depts'

const combinedReducers = combineReducers({
  app,
  user,
  auth,
  events,
  clients,
  drivers,
  payments,
  chantiers,
  debts,
})

function reducer(state, action) {
  if (action.type === LOGOUT.SUCCESS) {
    state = { ...pick(['app'], state) }
  }

  return combinedReducers(state, action)
}

export default reducer
