import React, { memo, useRef } from 'react'
import classes from 'classnames'
import MultiToggle from '../MultiToggle'
import ConfirmButton from '../ConfirmButton'
import format from 'date-fns/format'
import { useDrag } from 'react-dnd'
import { find, propEq } from 'ramda'
import fileIcon from '../../../../style/assets/file.png'
import editIcon from '../../../../style/assets/edit.png'
import deleteIcon from '../../../../style/assets/delete.png'
import invoiceIcon from '../../../../style/assets/invoice.png'
import dublicateIcon from '../../../../style/assets/dublicate.png'
import { sizeOptionsList, trashTypes } from '../../../../common/constants'

const copyAdress = rapperRef => {
  rapperRef.current.select()
  document.execCommand('copy')

  window.getSelection().removeAllRanges()
}

const EventItem = ({
  id,
  onOpenModal,
  client,
  user_id,
  driver,
  benne_size,
  location,
  comments,
  status,
  phone,
  phone_2,
  since,
  amount,
  capture_amount,
  distance,
  is_payd,
  is_overtime,
  has_hour,
  with_ttc,
  trash_type,
  particular,
  from_cabinet,
  onCopyEvent,
  showCaptureModal,
  showEventInvoices,
  updateEventstatus,
  onSetEventOwner,
  onRemoveEvent,
}) => {
  const adressWrapperRef = useRef(null)

  const date = format(
    since * 1000,
    has_hour ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy',
  )
  const benneSize = find(propEq('id', benne_size), sizeOptionsList)
  const trashType = find(propEq('id', trash_type), trashTypes)

  const formattedLocation =
    location.split('--')[1] === '1' ? location.split('--')[0] : location

  const [{ isDragging }, drag] = useDrag({
    item: {
      id,
      date,
      amount,
      client,
      location,
      benneSize,
      type: 'EVENT_LIST_ITEM',
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <div
      className={classes('event', { particular, cabinet: from_cabinet, withDriver: driver })}
      ref={drag}
      style={{ opacity: isDragging ? 0.4 : 1 }}
    >
      <div className="eventHeader">
        <div className="label">{client.label}</div>
        {(user_id && (
          <div className="controls">
            <MultiToggle value={status} onClick={updateEventstatus} />
            <button className="button blank icon-btn" onClick={onCopyEvent} title='dupliquer'>
              <img src={dublicateIcon} alt='' style={{ width: 20, aspectRatio: 1 }}/>
            </button>
            <button className="button blank icon-btn" onClick={onOpenModal} title='edit'>
              <img src={editIcon} alt='' style={{ width: 20, aspectRatio: 1 }}/>
            </button>
            <button className="button blank icon-btn" onClick={showEventInvoices} title='attacements'>
              <img src={fileIcon} alt='' style={{ width: 20, aspectRatio: 1 }}/>
            </button>
            {
              from_cabinet && is_payd && (
                <button className="button blank icon-btn" onClick={showCaptureModal} title='attacements'>
                  <img src={invoiceIcon} alt='' style={{ width: 20, aspectRatio: 1 }}/>
                </button>
              )
            }
            {
              !from_cabinet && (
                <ConfirmButton onClick={onRemoveEvent} label={(
                  <img src={deleteIcon} alt='' style={{ width: 20, aspectRatio: 1 }}/>
                )} className='blank icon-btn' />
              )
            }
          </div>
        )) || (
          <div className="controls">
            <button className="button" onClick={onSetEventOwner}>
              Trait
            </button>
          </div>
        )}
      </div>
      <div className="footer">
        <div className="phoneNumbers">
          {phone && (
            <a href={`tel:${phone}`} className="phoneLink own">
              {phone}
            </a>
          )}
          {
            phone_2 && (
              <a href={`tel:${phone_2}`} className="phoneLink own">
                {phone_2}
              </a>
            )
          }
          <a href={`tel:${client.phone}`} className="phoneLink">
            {client.phone}
          </a>
          {client.phone_2 && (
            <a href={`tel:${client.phone_2}`} className="phoneLink">
              {client.phone_2}
            </a>
          )}
          {client.phone_3 && (
            <a href={`tel:${client.phone_3}`} className="phoneLink">
              {client.phone_3}
            </a>
          )}
        </div>
        <textarea
          className="location"
          onClick={() => copyAdress(adressWrapperRef)}
          ref={adressWrapperRef}
          value={formattedLocation}
          readOnly
          resizable="false"
        />
        <div className="details">
          <div
            className={classes('status', {
              active: status === '2' || status === '1',
            })}
          />
          {particular && (
            <div className="status particular" />
          )}
          {from_cabinet && (
            <div className="status cabinet" />
          )}
          <div className={classes('since', { includeHour: has_hour })}>
            {date}
          </div>
          <div>{driver && driver.label}</div>
          <div>{benneSize.label}</div>
          <div>{!!is_overtime && 'Travail supplémentaire'}</div>
          {
            trashType && (
              <div className={`trashType ${trashType.label}`}>
                {trashType.label}
              </div>
            )
          }
          {
            !!from_cabinet ? (
              <div>{Math.ceil(amount * 1.20)}€ ({capture_amount}€ Remise)</div>
            ) : (with_ttc && (
              <div>{!!amount && `${amount}€ HT (${Math.ceil(amount * 1.20)}€ TTC)`}</div>
            )) || (
              <div>{amount}€ CASH</div>
            )
          }
          <div>{!!distance && `distance: ${distance}km`}</div>
          <div className="warn">{!!is_payd ? (from_cabinet && 'Authorised') || 'Paye' : ''}</div>
        </div>
        {
          comments && (
            <div className='comments'><strong>commentaire:</strong> {comments}</div>
          )
        }
      </div>
    </div>
  )
}

export default memo(EventItem)
