import { EVENTS } from './types'

export const onAddNewEvent = payload => ({
  type: EVENTS.REQUEST_ADD,
  payload,
})

export const onAddNewEventSucces = payload => ({
  type: EVENTS.ADD,
  payload,
})

export const requestLoadEventsList = payload => ({
  type: EVENTS.REQUEST_LOAD,
  payload,
})

export const requestEventInvoices = payload => ({
  type: EVENTS.REQUEST_INVOICES,
  payload,
})

export const requestEventCapture = payload => ({
  type: EVENTS.REQUEST_CAPTURE,
  payload,
})

export const captureEventSuccess = payload => ({
  type: EVENTS.CAPTURE_SUCCESS,
  payload,
})

export const requestEventInvoice = payload => ({
  type: EVENTS.REQUEST_INVOICE,
  payload
})

export const requestRemoveEventInvoice = payload => ({
  type: EVENTS.REQUEST_INVOICE_REMOVE,
  payload
})

export const requestUploadEventInvoice = payload => ({
  type: EVENTS.REQUEST_INVOICE_UPLOAD,
  payload,
})

export const getEventsSucces = payload => ({
  type: EVENTS.GET_LIST,
  payload,
})

export const requestUpdateEvent = payload => ({
  type: EVENTS.REQUEST_UPDATE,
  payload,
})

export const updateEventSucces = payload => ({
  type: EVENTS.UPDATE,
  payload,
})

export const requestRemoveEvent = payload => ({
  type: EVENTS.REQUEST_REMOVE,
  payload,
})

export const removeEventSucces = payload => ({
  type: EVENTS.REMOVE,
  payload,
})

export const requestUpdateEventStatus = ({ eventId, status }) => ({
  type: EVENTS.REQUEST_UPDATE_STATUS,
  payload: {
    eventId,
    status,
  },
})

export const updateEventStatusSucces = payload => ({
  type: EVENTS.UPDATE_STATUS,
  payload,
})

export const requestUpdateEventOwner = payload => ({
  type: EVENTS.REQUEST_UPDATE_OWNER,
  payload,
})

export const updateEventOwnerSucces = payload => ({
  type: EVENTS.UPDATE_OWNER,
  payload,
})

export const requestRetrievableEvents = payload => ({
  type: EVENTS.REQUEST_RETRIEVABLE,
  payload,
})
