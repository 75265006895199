export const LOGIN = {
  REQUEST: 'LOGIN_REQUEST',
  SUCCESS: 'LOGIN_SUCCESS',
  FAILURE: 'LOGIN_FAILURE',
  CLEAR_ERRORS: 'CLEAR_LOGIN_ERRORS',
}

export const LOGOUT = {
  REQUEST: 'LOGOUT_REQUEST',
  SUCCESS: 'LOGOUT_SUCCESS',
  FAILURE: 'LOGOUT_FAILURE',
}

export const AUTH_STATE = {
  REQUEST: 'AUTH_STATE_REQUEST',
  SUCCESS: 'AUTH_STATE_SUCCESS',
  FAILURE: 'AUTH_STATE_FAILURE',
}

export const PROFILE = {
  SET_DETAILS: 'SET_DETAILS',
}

export const TOKEN = {
  REFRESH: 'REFRESH_TOKEN',
}
