import React from 'react'
import { Layout } from '../../common/components'

const NotFoundPage = () => {
  return (
    <Layout>
      <h2>404 page pas existe</h2>
    </Layout>
  )
}

export default NotFoundPage
