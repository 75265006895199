import { PAYMENTS } from '../../actions/payments/types'

function reducer(state = {}, action) {
  switch (action.type) {
    case PAYMENTS.SET_BRAINTREE_TOKEN:
      return {
        ...state,
        braintreeToken: action.payload,
      }
    default:
      return state
  }
}

export default reducer
