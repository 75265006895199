import React from 'react'
import format from 'date-fns/format'
import { find, propEq } from 'ramda'
import { sizeOptionsList } from '../../../../common/constants'
import MultiToggle from '../MultiToggle'

const ChantierItem = ({
  client,
  driver,
  has_hour,
  location,
  benne_size,
  status,
  since,
}) => {
  const dateFormat = has_hour ? 'dd.MM.yyyy h:mm aa' : 'dd.MM.yyyy'
  const benneSize = find(propEq('id', benne_size), sizeOptionsList)

  return (
    <div className="chantier">
      <div className="client">{client.label}</div>
      <p className="details">{location}</p>
      <div className="details">
        <div>
          <span>{format(since * 1000, dateFormat)}</span>
          <span>{benneSize.label}</span>
          <span>{driver && driver.label}</span>
        </div>
        <div>
          <MultiToggle value={status} disabled />
        </div>
      </div>
    </div>
  )
}

export default ChantierItem
