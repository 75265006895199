import getUnixTime from 'date-fns/getUnixTime'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChantiersFetchingStatus } from '../../../../selectors'
import { requestUserChantiers } from '../../../../actions/chantiers'
import { LoadingSpinner, Tabs } from '../../../../common/components'
import ChantierItem from './ChantierItem'
import ItemsMap from './ItemsMap'
import './style.scss'

const ChantiersList = ({ list, filterModel, period }) => {
  const dispatch = useDispatch()
  const listIsFetching = useSelector(getChantiersFetchingStatus)

  useEffect(() => {
    dispatch(
      requestUserChantiers({
        ...filterModel,
        since: getUnixTime(period.interval.since),
        until: getUnixTime(period.interval.until),
      }),
    )
  }, [filterModel, period])

  return (
    <Tabs
      className="chantiersListTab"
      items={[
        {
          id: 'chantiersListList',
          title: `List`,
          content: (
            <div className="chantiersList">
              {listIsFetching ? (
                <LoadingSpinner />
              ) : list.map(chantier => (
                <ChantierItem
                  {...chantier}
                  key={chantier.id}
                />
              ))}
            </div>
          ),
        },
        {
          id: 'chantiersListMap',
          title: `Map`,
          content: (
            <ItemsMap elements={list} />
          ),
        },
      ]}
    />
  )
}

export default ChantiersList
