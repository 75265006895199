import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestEventInvoice, requestEventInvoices, requestUploadEventInvoice, requestRemoveEventInvoice} from '../../../../actions/events'
import { FormInput } from '../../../../common/components'
import ConfirmButton from '../ConfirmButton'

const EventInvoicesModal = ({ event }) => {
  const dispatch = useDispatch()
  const [invoicesList, setInvoicesList] = useState([])

  const handleUpload = (changeEvent) => {
    if (changeEvent.target.files) {
      dispatch(requestUploadEventInvoice({
        eventId: event.id,
        file: changeEvent.target.files[0],
        submitCallback: (response) => {
          setInvoicesList([...invoicesList, response])
        }
      }))
    }
  }

  const onRequestEventInvoice = (invoice) => {
    dispatch(requestEventInvoice({
      invoiceId: invoice.id,
      eventId: invoice.event_id,
      submitCallback: (file) => {
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = invoice.filename;
        link.click();
        link.remove();
      }
    }))
  }

  const onRequestRemoveEventInvoice = (invoice) => {
    dispatch(requestRemoveEventInvoice({
      invoiceId: invoice.id,
      eventId: invoice.event_id,
      submitCallback: () => {
        setInvoicesList(invoicesList.filter(({ id }) => id !== invoice.id))
      }
    }))
  }

  useEffect(() => {
    dispatch(requestEventInvoices({
      eventId: event.id,
      submitCallback: setInvoicesList
    }))
  }, [dispatch, event.id])

  return (
    <div className="receipts modal">
      <FormInput
        type='file'
        label='attachez facture'
        onChange={handleUpload}
        accept=".pdf, .xls, .xlsx"
      />
      <div  className='receiptsList'>
        {
          invoicesList.map(invoice => (
            <div key={invoice.id} className='receiptItem'>
              <div onClick={() => onRequestEventInvoice(invoice)}>{invoice.filename}</div>
              <ConfirmButton onClick={() => onRequestRemoveEventInvoice(invoice)} label="supprimer" />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default EventInvoicesModal
