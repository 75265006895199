import { all, call } from 'redux-saga/effects'

import authSaga from './sagas/auth'
import eventsSaga from './sagas/events'
import driversSaga from './sagas/drivers'
import clientsSaga from './sagas/clients'
import paymentsSaga from './sagas/payments'
import chantiersSaga from './sagas/chantiers'
import debtsSaga from './sagas/debts'
import feedbacksSaga from './sagas/feedbacks'

function* rootSaga() {
  yield all([
    call(authSaga),
    call(eventsSaga),
    call(driversSaga),
    call(clientsSaga),
    call(paymentsSaga),
    call(chantiersSaga),
    call(debtsSaga),
    call(feedbacksSaga),
  ])
}

export default rootSaga
