import React, { useState } from 'react'
import propTypes from 'prop-types'
import parse from 'date-fns/parse'
import fromUnixTime from 'date-fns/fromUnixTime'
import { useDispatch, useSelector } from 'react-redux'
import { ascend, path, prop, pipe, sort, descend } from 'ramda'

import {
  requestUpdateEvent,
  requestRemoveEvent,
  requestUpdateEventOwner,
  requestUpdateEventStatus,
} from '../../../../actions/events'

import { formIsFetchingStatus } from '../../../../selectors'

import { Popup, LoadingSpinner } from '../../../../common/components'

import NewEventModal from '../NewEventModal/ModalContent'
import EventInvoicesModal from './EventInvoicesModal'
import CopyEventModal from './CopyEventModal'
import CaptureModal from './CaptureModal'
import EventItem from './EventItem'

import './style.scss'

const byClientName = ascend(path(['client', 'label']))
const byEventSince = descend(prop('since'))

const sortEvents = pipe(sort(byEventSince), sort(byClientName))

const EventsList = ({
  list,
  clientsList,
  driversList,
  loadingMore,
  requestEventsList,
  eventsCount,
}) => {
  const [editableEvent, setEditableEvent] = useState(null)
  const [eventToCopy, setEventToCopy] = useState(null)
  const [eventInvoices, setEventInvoices] = useState(null)
  const [eventCapture, setEventCapture] = useState(null)

  const dispatch = useDispatch()

  const formIsFetching = useSelector(formIsFetchingStatus)

  const onScrollEventsList = ({
    target: { scrollHeight, scrollTop, offsetHeight },
  }) => {
    const scrollBottom = scrollHeight - scrollTop - offsetHeight

    if (
      eventsCount.total !== eventsCount.loaded &&
      scrollBottom < 99 &&
      !loadingMore
    ) {
      requestEventsList()
    }
  }

  const onChangeStatus = formData => {
    dispatch(requestUpdateEvent(formData))
  }

  const onOpenModal = formData => {
    setEditableEvent(formData)
  }

  const onRemoveEvent = ({ id, amount }) =>
    dispatch(requestRemoveEvent({ id, amount }))

  const updateEventstatus = (eventId, status) => {
    dispatch(requestUpdateEventStatus({ eventId, status }))
  }

  const onSetEventOwner = eventId => {
    dispatch(requestUpdateEventOwner({ eventId }))
  }

  const onCopyEvent = event => {
    setEventToCopy(event)
  }

  return (
    <div className="extendedEventsList" onScroll={onScrollEventsList}>
      {Object.keys(list)
        .sort((date, nextDate) => (
          parse(date, 'dd-MM-yyyy', new Date()) - parse(nextDate, 'dd-MM-yyyy', new Date())
        )).reverse()
        .map(date => (
          <div key={date} className="dayContainer">
            <span className="date">{date}</span>
            {sortEvents(list[date]).map(event => (
              <EventItem
                key={event.id}
                {...event}
                updateEventstatus={newStatus =>
                  updateEventstatus(event.id, newStatus)
                }
                onCopyEvent={() => onCopyEvent(event)}
                onOpenModal={() => onOpenModal(event)}
                onRemoveEvent={() => onRemoveEvent(event)}
                onSetEventOwner={() => onSetEventOwner(event.id)}
                showEventInvoices={() => setEventInvoices(event)}
                showCaptureModal={() => setEventCapture(event)}
              />
            ))}
          </div>
        ))}

      {editableEvent && (
        <Popup onClose={() => setEditableEvent(null)}>
          <NewEventModal
            editMode
            onSubmit={onChangeStatus}
            driversList={driversList}
            clientsList={clientsList}
            defaultformData={{
              ...editableEvent,
              since: fromUnixTime(editableEvent.since),
              location: {
                location: editableEvent.location.split('--')[0],
                location_lng: editableEvent.location_lng,
                location_lat: editableEvent.location_lat,
                location_id: editableEvent.location_id || '',
              },
              order_count:
                parseInt(editableEvent.location.split('--')[1], 10) || 1,
            }}
            formIsFetching={formIsFetching}
          />
        </Popup>
      )}

      {eventToCopy && (
        <Popup onClose={() => setEventToCopy(null)}>
          <CopyEventModal eventToCopy={eventToCopy} />
        </Popup>
      )}

      {eventInvoices && (
        <Popup onClose={() => setEventInvoices(null)}>
          <EventInvoicesModal event={eventInvoices} />
        </Popup>
      )}

      {
        eventCapture && (
          <Popup onClose={() => setEventCapture(null)}>
            <CaptureModal
              event={eventCapture}
              setEvent={setEventCapture}
            />
          </Popup>  
        )
      }

      {loadingMore && (
        <div className="event container fullWidth">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

EventsList.propTypes = {
  list: propTypes.instanceOf(Object).isRequired,
  driversList: propTypes.array.isRequired,
}

export default EventsList
