import { registerLocale } from 'react-datepicker'
import frenchLocale from 'date-fns/locale/fr'
import React, { useEffect, useCallback, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { requestFetchInItialState } from './actions/auth'
import { determineUIVersion, switchUIVersion } from './actions'
import { LoadingSpinner } from './common/components'
import { getAppFetchingStatus, getAppUIVersion } from './selectors'
import Terms, { CompanyReview, PrivacyPolicy, CompanyTerms } from './pages/Terms'
import LogInPage from './pages/Login'
import NotFoundPage from './pages/NotFound'
import Deshboard  from './pages/Deshboard'
import CheckoutLinkPage from './pages/Checkout/CheckoutLink'

registerLocale('fr', frenchLocale)

const App = () => {
  const dispatch = useDispatch()
  const appIsFetching = useSelector(getAppFetchingStatus)
  const UIVersion = useSelector(getAppUIVersion)

  const switchUIVersionIfNeeded = useCallback(() => {
    const DUIVersion = determineUIVersion()

    if (DUIVersion !== UIVersion) {
      dispatch(switchUIVersion(DUIVersion))
    }
  }, [UIVersion, dispatch])

  useEffect(() => {
    window.addEventListener('resize', switchUIVersionIfNeeded)

    return () => {
      window.removeEventListener('resize', switchUIVersionIfNeeded)
    }
  }, [dispatch, switchUIVersionIfNeeded])

  useEffect(() => {
    dispatch(requestFetchInItialState())
  }, [dispatch])

  if (appIsFetching) {
    return <LoadingSpinner />
  }

  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() => (
          <Redirect to="/login" />
        )}
      />
      <Route path='/checkout/:checkoutId' exact component={CheckoutLinkPage} />
      <Route path='/reglement' exact component={CompanyTerms} />
      <Route path="/legal" exact component={CompanyReview} />
      <Route path="/terms" exact component={Terms} />
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      <Route
        path="/deshboard"
        exact
        render={() => (
          <Suspense fallback={<LoadingSpinner />}>
            <Deshboard />
          </Suspense>
        )}
      />
      <Route path="/login" component={LogInPage} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default App
