import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import getUnixTime from 'date-fns/getUnixTime'

import { requestLoadEventsList } from '../../actions/events'
import { Layout } from '../../common/components'

import {
  getAuthStatus,
  getEventsList,
  getDriversList,
  getClientsList,
  getAppUIVersion,
  getEventsCount,
  getActiveChantiersList,
} from '../../selectors'

import MobileVersion from './Mobile'
import DescktopVersion from './Descktop'
import './style.scss'

const Deshboard = () => {
  const eventsCount = useSelector(getEventsCount)
  const eventsList = useSelector(getEventsList)
  const reduxDriversList = useSelector(getDriversList)
  const reduxClientsList = useSelector(getClientsList)
  const chantiersList = useSelector(getActiveChantiersList)
  const isAuthentificated = useSelector(getAuthStatus)
  const uiVersion = useSelector(getAppUIVersion)

  const [loadingMore, setIsLoadingMore] = useState()
  const [filterModel, setFilterModel] = useState()
  const [activeTab, setActiveTab] = useState('activites')
  const [period, setPeriod] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    if (period) {
      setIsLoadingMore(true)

      dispatch(
        requestLoadEventsList({
          ...filterModel,
          take: 100,
          shouldResetList: true,
          since: getUnixTime(period.interval.since),
          until: getUnixTime(period.interval.until),
          submitCallback: response => {
            if (response.status === 200) {
              setIsLoadingMore(false)
            }
          },
        }),
      )
    }
  }, [dispatch, filterModel, period])

  const requestEventsList = () => {
    setIsLoadingMore(true)

    dispatch(
      requestLoadEventsList({
        ...filterModel,
        take: 100,
        skip: eventsCount.loaded,
        since: getUnixTime(period.interval.since),
        until: getUnixTime(period.interval.until),
        submitCallback: response => {
          if (response.status === 200) {
            setIsLoadingMore(false)
          }
        },
      }),
    )
  }

  if (!isAuthentificated) {
    return <Redirect to="/login" />
  }

  const LayoutContent = uiVersion === 'mobile' ? MobileVersion : DescktopVersion

  return (
    <Layout>
      <DndProvider backend={HTML5Backend}>
        <LayoutContent
          onSetActiveTab={setActiveTab}
          eventsCount={eventsCount}
          eventsList={eventsList}
          chantiersList={chantiersList}
          clientsList={Object.values(reduxClientsList)}
          driversList={Object.values(reduxDriversList)}
          requestEventsList={requestEventsList}
          setFilterModel={setFilterModel}
          filterModel={filterModel}
          setPeriod={setPeriod}
          loadingMore={loadingMore}
          activeTab={activeTab}
          period={period}
        />
      </DndProvider>
    </Layout>
  )
}
export default Deshboard
