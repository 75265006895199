import { propEq, remove, update } from 'ramda'
import { DEBTS } from '../../actions/debts/types'

const updateDebt = (state, action) => {
  const debtIndex = state.list.findIndex(propEq('id', action.payload.id))

  if (debtIndex !== -1) {
    return {
      ...state,
      list: update(
        debtIndex,
        {
          ...state.list[debtIndex],
          ...action.payload,
        },
        state.list,
      ),
    }
  }

  return state
}

const addDebtPart = (state, action) => {
  const debtIndex = state.list.findIndex(propEq('id', action.payload.debt_id))

  if (debtIndex !== -1) {
    return {
      ...state,
      list: update(
        debtIndex,
        {
          ...state.list[debtIndex],
          parts: state.list[debtIndex].parts.concat(action.payload),
        },
        state.list,
      ),
    }
  }

  return state
}

const removeDebt = (state, action) => {
  const debtIndex = state.list.findIndex(propEq('id', action.payload))

  if (debtIndex !== -1) {
    return {
      ...state,
      list: remove(debtIndex, 1, state.list),
    }
  }

  return state
}

const removeDebtPart = (state, { payload: { debtId, debtPartId } }) => {
  const debtIndex = state.list.findIndex(propEq('id', debtId))

  if (debtIndex !== -1) {
    const debtPartIndex = state.list[debtIndex].parts.findIndex(
      propEq('id', debtPartId),
    )

    if (debtPartIndex !== -1) {
      return {
        ...state,
        list: update(
          debtIndex,
          {
            ...state.list[debtIndex],
            parts: remove(debtPartIndex, 1, state.list[debtIndex].parts),
          },
          state.list,
        ),
      }
    }
  }

  return state
}

function reducer(
  state = {
    list: [],
    total_items_count: 0,
  },
  action,
) {
  switch (action.type) {
    case DEBTS.GET_LIST:
      return action.payload
    case DEBTS.ADD:
      return {
        total_items_count: state.total_items_count + 1,
        list: state.list.concat(action.payload),
      }
    case DEBTS.UPDATE:
      return updateDebt(state, action)
    case DEBTS.ADD_PART:
      return addDebtPart(state, action)
    case DEBTS.REMOVE:
      return removeDebt(state, action)
    case DEBTS.REMOVE_PART:
      return removeDebtPart(state, action)
    default:
      return state
  }
}

export default reducer
