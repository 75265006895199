import format from 'date-fns/format'
import { createSelector } from 'reselect'
import {
  pipe,
  descend,
  either,
  defaultTo,
  groupBy,
  prop,
  path,
  pathOr,
  propEq,
  sort,
  reject,
} from 'ramda'

export const getAppFetchingStatus = createSelector(
  path(['app', 'isFetching']),
  defaultTo(true),
)

export const formIsFetchingStatus = createSelector(
  path(['app', 'formIsFetching']),
  defaultTo(false),
)

export const getAuthStatus = createSelector(
  path(['auth', 'isAuthenticated']),
  defaultTo(false),
)

export const getLoginIsFetchingStatus = createSelector(
  path(['auth', 'loginIsFetching']),
  defaultTo(false),
)

export const getDriversList = createSelector(prop('drivers'), defaultTo({}))
export const getClientsList = createSelector(prop('clients'), defaultTo({}))
export const getDebtsList = createSelector(path(['debts', 'list']), (debts) => debts.map(debt => {
  const paidAmount = debt.parts.reduce((acc, debtPart) => acc + debtPart.amount, 0)

  return {
    ...debt,
    paidAmount,
    unpaidAmount: debt.amount - paidAmount
  }
}))

const getDebtsByPaidStatus = (list, status) => {
  if(status) {
    return list.filter(({ paidAmount, amount }) => (
      status === '1' ? paidAmount === amount : paidAmount !== amount
    ))
  } 
  
  return list
}
export const getDebtsAmount = paidStatus => createSelector(getDebtsList, (list) => {
  const debtsList = getDebtsByPaidStatus(list, paidStatus)

  return {
    total: debtsList.reduce((acc, { amount }) => acc + amount, 0),
    unpaid: debtsList.reduce((acc, { unpaidAmount }) => acc + unpaidAmount, 0)
  }
})

const groupEvents = (events, clients, drivers) =>
groupBy(
  prop('date'),
  events.map(({ driver, client, ...event }) => ({
    ...event,
    client: clients[event.client_id] || client,
    driver: drivers[event.driver_id] || driver,
    date: format(event.since * 1000, 'dd-MM-yyyy'),
  })),
)

export const getEventsList = createSelector(
  path(['events', 'list']),
  getClientsList,
  getDriversList,
  groupEvents,
)

export const getEventsCount = createSelector(
  path(['events', 'eventsCount']),
  defaultTo(0),
)

export const getEventsAmount = createSelector(
  path(['events', 'amount']),
  defaultTo(0),
)

export const getEventsCashAmount = createSelector(
  path(['events', 'cashAmount']),
  defaultTo(0),
)

export const getChantiersFetchingStatus = createSelector(
  path(['chantiers', 'isFetching']),
  defaultTo(true),
)

export const getActiveChantiersList = createSelector(
  pathOr([], ['chantiers', 'list']),
  pipe(
    reject(either(propEq('status', '0'), propEq('status', '3'))),
    sort(descend(prop('since'))),
  ),
)

export const getAppUIVersion = createSelector(
  path(['app', 'uiVersion']),
  defaultTo('descktop'),
)

export const getBraintreeToken = createSelector(
  path(['payments', 'braintreeToken']),
  defaultTo(''),
)

export const getAuthUser = createSelector(path(['user']), defaultTo(null))
