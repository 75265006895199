import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { requestPaymentLink } from '../../actions/debts';
import logo from '../../style/assets/ama_logo.png'

const CheckoutLinkPage = () => {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [isFetching, setIsFetching] = useState(true)
  const [paymentLink, setPaymentLink] = useState(null)

  useEffect(() => {
    if(routeParams.checkoutId) {
      dispatch(requestPaymentLink({
        checkoutId: routeParams.checkoutId,
        submitCallback: (response) => {
          setIsFetching(false)
  
          if(response.url) {
            setPaymentLink(response.url)
          }
        }
      }))
    }
  }, [])

  return (
    <div className='payment-page-wrap'>
      <a href='https://ama-loc.fr/'>
        <img src={logo} alt='logo' className='logo' />
      </a>
      <p>Effectuez un paiement en ligne pour location de benne en utilisant le lien suivant:</p>
      <br/>
      <br/>
      {
        isFetching ? 'chargement...' : (paymentLink && (
          <a href={paymentLink} className='link'>effectuez virement</a>
        ) || "virement link n'est pas disponible")
      }
    </div>
  )
}

export default CheckoutLinkPage