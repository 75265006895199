import React, { useState, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { move, propEq, remove } from 'ramda'
import CardItem from './CardItem'

import './style.scss'

const InvoiceGenerator = () => {
  const [cards, setCards] = useState([])

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setCards(move(dragIndex, hoverIndex, cards))
    },
    [cards],
  )

  const removeCard = useCallback(cardId => {
    const cardIndex = cards.findIndex(propEq('id', cardId))

    if (cardIndex !== -1) {
      setCards(remove(cardIndex, 1, cards))
    }
  })

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'EVENT_LIST_ITEM',
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: item => {
      if (item) {
        const itemAlreadyExists = cards.find(propEq('id', item.id))

        if (!itemAlreadyExists) {
          setCards([item, ...cards])
        }
      }
    },
  })

  return (
    <div className="invoiceGenerator">
      <div className="header">
        <button className="button">download facture</button>
      </div>
      <div className="invoiceItems" ref={drop}>
        {canDrop && isOver && (
          <div className="success-bg">faites glisser ici</div>
        )}
        {(!!cards.length &&
          cards.map((card, index) => (
            <CardItem
              key={card.id}
              index={index}
              moveCard={moveCard}
              removeCard={removeCard}
              {...card}
            />
          ))) || <div>0 items.</div>}
      </div>
    </div>
  )
}

export default InvoiceGenerator
