import React, { useState } from 'react'
import { requestCreateDebtPart } from '../../../../actions/debts'
import { useDispatch } from 'react-redux'
import { useFormValidator } from '../../../../common/hooks'
import { FormInput } from '../../../../common/components'

const DebtPartForm = ({ client, debt_id, maxValue }) => {
  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(false)
  const [debtAmount, setDebtAmount] = useState(1)
  const [withCard, setWithCard] = useState(false)

  const { onSubmitForm, statusNode } = useFormValidator({})

  const onKeyPress = e => {
    const keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()

      return false
    }
  }

  const handleSubmitForm = e => {
    e.preventDefault()
    setIsFetching(true)

    dispatch(
      requestCreateDebtPart({
        formData: {
          debt_id: debt_id,
          with_card: Number(withCard),
          amount: debtAmount > maxValue ? maxValue : debtAmount,
        },
        submitCallback: response => {
          onSubmitForm(response)
          setDebtAmount(0)
          setIsFetching(false)
        },
      }),
    )
  }

  return (
    <form
      onKeyPress={onKeyPress}
      onSubmit={handleSubmitForm}
      className="inputForm modal"
    >
      <FormInput
        label={`Montante: ${client.label} (max: ${maxValue})`}
        name="montante"
        type="number"
        min={1}
        max={maxValue}
        required
        value={debtAmount}
        onChange={({ target }) => setDebtAmount(target.value)}
      />

      <div className="formInput checbox">
        Card Virament
        <input
          type="checkbox"
          checked={withCard}
          onChange={() => {
            setWithCard(!withCard)
          }}
        />
      </div>

      <div className="formFooter">
        {statusNode}
        <button type="submit" className="button" disabled={isFetching}>
          submit
        </button>
      </div>
    </form>
  )
}

export default DebtPartForm
