export const DEBTS = {
  ADD: 'ADD_DEBT',
  UPDATE: 'UPDATE_DEBT',
  REMOVE: 'REMOVE_DEBT',
  GET_LIST: 'GET_DEBTS_LIST',
  ADD_PART: 'ADD_DEBT_PART',
  REMOVE_PART: 'REMOVE_DEBT_PART',
  REQUEST_ADD: 'REQUEST_ADD_DEBT',
  REQUEST_LIST: 'REQUEST_DEBTS_LIST',
  REQUEST_REMOVE: 'REQUEST_REMOVE_DEBT',
  REQUEST_ADD_PART: 'REQUEST_ADD_DEBT_PART',
  REQUEST_REMOVE_PART: 'REQUEST_REMOVE_DEBT_PART',
  REQUEST_PAYMENT_LINK: 'REQUEST_DEBT_PAYMENT_LINK'
}
