import { all, call, put, takeEvery } from 'redux-saga/effects'
import authorizedApiRequest from '../../../common/fetch/authorized'
import appendToFormData from '../../../common/fetch/appendToFormData'

import {
  getClientsListSucces,
  onAddNewClientSucces,
  onRemoveClientSucces,
  onUpdateClientSuccess,
} from '../../../actions/clients'

import { CLIENTS } from '../../../actions/clients/types'
import { formIsFetching } from '../../../actions'

export function* fetchClientsList() {
  const payloadResponse = yield call(authorizedApiRequest, '/clients', {
    method: 'GET',
  })

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(getClientsListSucces(serverResponse))
  }
}

function* addClient({ payload: { formData, submitCallback } }) {
  yield put(formIsFetching(true))

  const payloadResponse = yield call(authorizedApiRequest, '/clients', {
    method: 'POST',
    body: appendToFormData(new FormData(), formData),
  })

  yield put(formIsFetching(false))

  submitCallback(payloadResponse)

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(onAddNewClientSucces(serverResponse))
  }
}

function* updateClient({ payload: { formData, submitCallback } }) {
  const payloadResponse = yield call(authorizedApiRequest, '/clients', {
    method: 'POST',
    body: appendToFormData(new FormData(), { ...formData, _method: 'PUT' }),
  })

  submitCallback(payloadResponse)

  if (payloadResponse.status === 204) {
    yield put(onUpdateClientSuccess(formData))
  }
}

function* removeClient({ payload }) {
  const payloadResponse = yield call(
    authorizedApiRequest,
    `/clients/${payload}`,
    {
      method: 'POST',
    },
  )

  if (payloadResponse.status === 200) {
    yield put(onRemoveClientSucces(payload))
  }
}

export default function* clientsSagaWatcher() {
  yield all([
    takeEvery(CLIENTS.REQUEST_ADD, addClient),
    takeEvery(CLIENTS.REQUEST_UPDATE, updateClient),
    takeEvery(CLIENTS.REQUEST_REMOVE, removeClient),
  ])
}
