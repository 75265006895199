import { depoCoords } from '../constants'
export { default as config } from './config'

export const getDistanceToClient = (
    { location_lat, location_lng },
    callback,
  ) => {
    const directionService = new window.google.maps.DistanceMatrixService()
  
    const origin = new window.google.maps.LatLng(location_lat, location_lng)
    const destination = new window.google.maps.LatLng(
      depoCoords.lat,
      depoCoords.lng,
    )
  
    directionService.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
      },
      (result, status) => {
        if (status === 'OK') {
          const payloadRs = result.rows[0].elements[0]
  
          callback({
            distance: payloadRs.distance.text.split(' ')[0].split(',')[0],
            duration_min: payloadRs.duration.text.split(' ')[0],
          })
        }
      },
    )
  }