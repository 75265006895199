import React, { Fragment, useState } from 'react'
import { useForm, useFormValidator } from '../../../../common/hooks'
import { FormInput } from '../../../../common/components'
import EditDriverForm from '../EditDriverForm'

const ModalContent = ({
  onSubmit,
  onRemoveDriver,
  driversList,
  formIsFetching,
}) => {
  const [editableDriver, setEditableDriver] = useState()
  const { onSubmitForm, statusNode } = useFormValidator({
    succes: 'chauffeur a été ajouté avec succès!',
  })

  const {
    handleResetForm,
    values: formData,
    handleChange: setFormData,
  } = useForm()

  const processSubmitResponse = formRef => response => {
    if (response.status === 200) {
      handleResetForm(formRef)
    }
  }

  const handleSubmitForm = e => {
    e.preventDefault()

    const submitFormRef = processSubmitResponse(e.target)

    onSubmit({
      formData,
      submitCallback: response => {
        submitFormRef(response)
        onSubmitForm(response)
      },
    })
  }

  const onCancelUpdate = () => {
    setEditableDriver(null)
  }

  return (
    <form onSubmit={handleSubmitForm} className="inputForm modal">
      <FormInput
        label="Camion"
        name="label"
        type="string"
        value={formData.label}
        onChange={setFormData}
      />

      <div className="formFooter">
        {statusNode}
        <button type="submit" className="button" disabled={formIsFetching}>
          submit
        </button>
      </div>

      <div className="driversList">
        {driversList.map(driver => (
          <div className="driverItem" key={driver.id}>
            {(editableDriver && editableDriver.id === driver.id && (
              <EditDriverForm {...driver} onCancel={onCancelUpdate} />
            )) || (
              <Fragment>
                <div>{driver.label}</div>
                <div className="controls">
                  <button
                    onClick={() => setEditableDriver(driver)}
                    type="button"
                  >
                    edit
                  </button>
                  <button
                    onClick={() => onRemoveDriver(driver.id)}
                    type="button"
                  >
                    supprimer
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        ))}
      </div>
    </form>
  )
}

export default ModalContent
