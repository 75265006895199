import { all, takeEvery, call, put } from 'redux-saga/effects'
import { setBraintreeToken } from '../../../actions/payments'
import { PAYMENTS } from '../../../actions/payments/types'
import appendToFormData from '../../../common/fetch/appendToFormData'
import apiRequest from '../../../common/fetch'

function* requestBraintreeToken() {
  const payloadResponse = yield call(
    apiRequest,
    '/payments/generate-client-token',
    {
      method: 'POST',
    },
  )

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield put(setBraintreeToken(serverResponse.clientToken))
  }
}

function* submitBraintreePayment({ payload: { formData, submitCallback } }) {
  const payloadResponse = yield call(apiRequest, '/payments/transaction', {
    method: 'POST',
    body: appendToFormData(new FormData(), formData),
  })

  submitCallback(payloadResponse)
}

function* submitPreorder({ payload: { formData, submitCallback } }) {
  const payloadResponse = yield call(apiRequest, '/payments/preorder', {
    method: 'POST',
    body: appendToFormData(new FormData(), formData),
  })

  submitCallback(payloadResponse)
}

export default function* eventsSagaWatcher() {
  yield all([
    takeEvery(PAYMENTS.SUBMIT_PREORDER, submitPreorder),
    takeEvery(PAYMENTS.SUBMIT_PAYMENT, submitBraintreePayment),
    takeEvery(PAYMENTS.REQUEST_BRAINTREE_TOKEN, requestBraintreeToken),
  ])
}
