import { DEBTS } from './types'

export const requestPaymentLink  = (payload) => ({
  type: DEBTS.REQUEST_PAYMENT_LINK,
  payload,
})

export const requestUserDebts = payload => ({
  type: DEBTS.REQUEST_LIST,
  payload,
})

export const getDebtsListSuccess = payload => ({
  type: DEBTS.GET_LIST,
  payload,
})

export const requestAddDebt = payload => ({
  type: DEBTS.REQUEST_ADD,
  payload,
})

export const addDebtSuccess = payload => ({
  type: DEBTS.ADD,
  payload,
})

export const updateDebt = payload => ({
  type: DEBTS.UPDATE,
  payload,
})

export const requestRemoveDebt = payload => ({
  type: DEBTS.REQUEST_REMOVE,
  payload,
})

export const removeDebtSuccess = payload => ({
  type: DEBTS.REMOVE,
  payload,
})

export const requestCreateDebtPart = payload => ({
  type: DEBTS.REQUEST_ADD_PART,
  payload,
})

export const createDebtPartSuccess = payload => ({
  type: DEBTS.ADD_PART,
  payload,
})

export const requestRemoveDebtPart = (debtId, debtPartId) => ({
  type: DEBTS.REQUEST_REMOVE_PART,
  payload: { debtId, debtPartId },
})

export const removeDebtPartSuccess = (debtId, debtPartId) => ({
  type: DEBTS.REMOVE_PART,
  payload: { debtId, debtPartId },
})
