import React, { Fragment, useMemo, useState } from 'react'
import { useForm, useFormValidator } from '../../../../common/hooks'
import { PlacesAutocomplete, FormInput } from '../../../../common/components'
import EditClientform from '../EditClientForm'

const NewClientModal = ({
  onSubmit,
  clientsList,
  onRemoveClient,
  formIsFetching,
}) => {
  const [editableclient, setEditableClient] = useState()
  const [filterQuery, setFilterQuery] = useState('')

  const { onSubmitForm, statusNode } = useFormValidator({
    succes: 'client a été ajouté avec succès!',
  })

  const {
    handleResetForm,
    customHandleChange,
    values: formData,
    handleChange: setFormData,
  } = useForm()

  const processSubmitResponse = formRef => response => {
    if (response.status === 200) {
      handleResetForm(formRef)
    }
  }

  const handleSubmitForm = e => {
    e.preventDefault()

    const submitFormRef = processSubmitResponse(e.target)

    onSubmit({
      formData,
      submitCallback: response => {
        submitFormRef(response)
        onSubmitForm(response)
      },
    })
  }

  const onSetFilterQuery = ({ target: { value } }) => {
    setFilterQuery(value)
  }

  const onCancelUpdate = () => {
    setEditableClient(null)
  }

  const onKeyPress = e => {
    const keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()

      return false
    }
  }

  const filteredClients = useMemo(
    () =>
      clientsList.filter(({ label }) =>
        label.toLowerCase().includes(filterQuery.toLowerCase()),
      ),
    [clientsList, filterQuery],
  )

  return (
    <form
      onSubmit={handleSubmitForm}
      onKeyPress={onKeyPress}
      className="inputForm modal"
    >
      <FormInput
        label="Nom"
        name="label"
        type="string"
        required
        value={formData.label}
        onChange={setFormData}
      />

      <FormInput
        label="Téléphone"
        name="phone"
        type="tel"
        required
        value={formData.phone}
        onChange={setFormData}
      />

      <FormInput
        label="Téléphone 2"
        name="phone_2"
        type="tel"
        value={formData.phone_2}
        onChange={setFormData}
        placeholder="optional"
      />

      <FormInput
        label="Téléphone 3"
        name="phone_3"
        type="tel"
        value={formData.phone_3}
        onChange={setFormData}
        placeholder="optional"
      />

      <div className="formInput">
        <label>Emplacement</label>
        <PlacesAutocomplete
          onChange={value => {
            customHandleChange('location', value)
          }}
          defaultValue={formData.location}
        />
      </div>

      <div className="formFooter">
        {statusNode}
        <button type="submit" className="button" disabled={formIsFetching}>
          submit
        </button>
      </div>

      <div className="driversList">
        <input
          placeholder="chercher..."
          className="formInput"
          onChange={onSetFilterQuery}
          value={filterQuery}
        />

        {filteredClients.map(client => (
          <div className="driverItem" key={client.id}>
            {(editableclient && editableclient.id === client.id && (
              <EditClientform onCancel={onCancelUpdate} client={client} />
            )) || (
              <Fragment>
                <div>{client.label}</div>
                <div className="controls">
                  <button
                    onClick={() => setEditableClient(client)}
                    type="button"
                  >
                    edit
                  </button>
                  <button
                    onClick={() => onRemoveClient(client.id)}
                    type="button"
                  >
                    supprimer
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        ))}
      </div>
    </form>
  )
}

export default NewClientModal
