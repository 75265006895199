export const EVENTS = {
  REQUEST_LOAD: 'REQUEST_LOAD',
  REQUEST_ADD: 'REQUEST_ADD_EVENT',
  REQUEST_REMOVE: 'REQUEST_REMOVE_EVENT',
  REQUEST_UPDATE: 'REQUEST_UPDATE_EVENT',
  REQUEST_UPDATE_OWNER: 'REQUEST_UPDATE_EVENT_OWNER',
  REQUEST_UPDATE_STATUS: 'REQUEST_UPDATE_EVENT_STATUS',
  REQUEST_INVOICE: 'REQUEST_EVENT_INVOICE',
  REQUEST_INVOICE_REMOVE: 'REQUEST_EVENT_INVOICE_REMOVE',
  REQUEST_INVOICE_UPLOAD: 'REQUEST_EVENT_INVOICE_UPLOAD',
  REQUEST_INVOICES: 'REQUEST_EVENT_INVOICES',
  ADD: 'ADD_EVENT',
  REMOVE: 'REMOVE_EVENT',
  UPDATE: 'UPDATE_EVENT',
  GET_LIST: 'GET_EVENTS_LIST',
  UPDATE_OWNER: 'UPDATE_EVENT_OWNER',
  UPDATE_STATUS: 'UPDATE_EVENT_STATUS',
  REQUEST_RETRIEVABLE: 'REQUEST_RETRIEVABLE_EVENTS',
  REQUEST_CAPTURE: 'REQUEST_CAPTURE_EVENT',
  CAPTURE_SUCCESS: 'EVENT_CAPTURE_SUCCESS'
}
