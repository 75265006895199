import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestEventCapture } from '../../../../actions/events'
import { FormInput } from '../../../../common/components'
import { captureServiceTypes } from '../../../../common/constants'
import { useFormValidator } from '../../../../common/hooks'
import { formIsFetchingStatus } from '../../../../selectors'

const CaptureModal = ({ event, setEvent }) => {
  const dispatch = useDispatch()
  const formIsFetching = useSelector(formIsFetchingStatus)
  const { onSubmitForm, statusNode } = useFormValidator({})
  const [captureAmount, setCaptureAmount] = useState(0)
  const [captureService, setCaptureService] = useState(captureServiceTypes['1'].id)

  const onRequestEventRefund = () => {
    if(captureAmount > (Math.ceil(event.amount * 1.20) - event.capture_amount)) return

    dispatch(requestEventCapture({
      refEventId: event.ref_event,
      eventId: event.id,
      captureService,
      nextCaptureAmount: captureAmount,
      submitCallback: (response) => {
        if(response.status === 200) {
          setEvent(prevState => ({
            ...prevState,
            capture_amount: prevState.capture_amount + captureAmount
          }))

          setCaptureAmount(0)
        }

        onSubmitForm(response)
      }
    }))
  }

  return (
    <div className="inputForm modal">
      <FormInput
        min="0"
        max={Math.ceil(event.amount * 1.20) - event.capture_amount}
        label="Remise €"
        name="captureAmount"
        type="number"
        value={captureAmount}
        onChange={({ target: { value }}) => setCaptureAmount(Number(value))}
      />

      <div className="formInput">
        <label htmlFor="status">Service ofert</label>
        <select
          value={captureService}
          onChange={e => setCaptureService(e.target.value)}
        >
          {Object.values(captureServiceTypes).map(service => (
            <option key={service.id} value={service.id}>
              {service.label}
            </option>
          ))}
        </select>
      </div>

      <div className="formFooter">
        {statusNode}
        <button type="submit" className="button" disabled={formIsFetching} onClick={onRequestEventRefund}>
          noveau Remise
        </button>
      </div>
      <div className='capture-totals'>
        <div className='info-row'>
          <strong>Autorisation du montant:</strong>
          <span>{Math.ceil(event.amount * 1.20)}€</span>
        </div>
        <div className='info-row'>
          <strong>Remise du montant:</strong>
          <span>{event.capture_amount}€</span>
        </div>
        <div className='info-row'>
          <strong>Remise max:</strong>
          <span>{Math.ceil(event.amount * 1.20) - event.capture_amount}€</span>
        </div>
      </div>
      <div className='capture-totals'>
        <div className='info-row'>
          <strong>Remise history:</strong>
          <span />
        </div>
        {
          event.captures.map(captureItem => (
            <div className='info-row'>
              <strong>{captureServiceTypes[captureItem.service_id].label}</strong>
              <span>{captureItem.amount}€</span>
            </div> 
          ))
        }
      </div>
    </div>
  )
}

export default CaptureModal
