import { CLIENTS } from '../../actions/clients/types'
import { omit } from 'ramda'

function reducer(state = {}, action) {
  switch (action.type) {
    case CLIENTS.GET_LIST:
      return action.payload.list.reduce(
        (acc, driver) => ({
          ...acc,
          [driver.id]: driver,
        }),
        {},
      )
    case CLIENTS.ADD:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    case CLIENTS.UPDATE:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    case CLIENTS.REMOVE:
      return omit([action.payload], state)
    default:
      return state
  }
}

export default reducer
