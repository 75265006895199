import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm, useFormValidator } from '../../../../common/hooks'
import { PlacesAutocomplete, FormInput } from '../../../../common/components'
import { onUpdateClient } from '../../../../actions/clients'

const EditClientForm = ({ onCancel, client }) => {
  const { onSubmitForm, statusNode } = useFormValidator({
    succes: 'chauffeur a été ajouté avec succès!',
  })

  const {
    customHandleChange,
    values: formData,
    handleChange: setFormData,
  } = useForm(client)

  const dispatch = useDispatch()

  const handleUpdateClient = () => {
    dispatch(
      onUpdateClient({
        formData,
        submitCallback: response => {
          onSubmitForm(response)

          if (response.status === 204) {
            setTimeout(onCancel, 2000)
          }
        },
      }),
    )
  }

  return (
    <div className="inputForm editFormItem">
      <FormInput
        label="Nom"
        name="label"
        type="string"
        required
        value={formData.label}
        onChange={setFormData}
      />

      <FormInput
        label="Téléphone"
        name="phone"
        type="tel"
        required
        value={formData.phone}
        onChange={setFormData}
      />

      <FormInput
        label="Téléphone 2"
        name="phone_2"
        type="tel"
        value={formData.phone_2}
        onChange={setFormData}
        placeholder="optional"
      />

      <FormInput
        label="Téléphone 3"
        name="phone_3"
        type="tel"
        value={formData.phone_3}
        onChange={setFormData}
        placeholder="optional"
      />

      <div className="formInput">
        <label>Emplacement</label>
        <PlacesAutocomplete
          onChange={value => {
            customHandleChange('location', value)
          }}
          defaultValue={formData.location}
        />
      </div>
      <div className="formFooter">
        {statusNode}
        <button onClick={handleUpdateClient} type="button">
          update
        </button>
        <button onClick={onCancel} type="button">
          anuler
        </button>
      </div>
    </div>
  )
}

export default EditClientForm
