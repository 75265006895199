import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import getUnixTime from 'date-fns/getUnixTime'
import { propEq } from 'ramda'
import { requestAddDebt } from '../../../../actions/debts'
import { useDispatch } from 'react-redux'
import { defaultSelectOption } from '../../../../common/constants'
import { SearchAutocomplete, FormInput } from '../../../../common/components'
import { useForm, useFormValidator } from '../../../../common/hooks'

const AddDebtClientForm = ({
  clientsList,
  editMode,
  formData: defaultformData,
}) => {
  const dispatch = useDispatch()

  const [isFetching, setIsFetching] = useState(false)
  const { onSubmitForm, statusNode } = useFormValidator({})

  const {
    values: formData,
    handleChange: setFormData,
    customHandleChange,
  } = useForm(defaultformData)

  const clientOptions = [defaultSelectOption, ...clientsList]

  const onKeyPress = e => {
    const keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()

      return false
    }
  }

  const handleSubmitForm = e => {
    e.preventDefault()
    setIsFetching(true)

    dispatch(
      requestAddDebt({
        editMode,
        formData: {
          id: formData.id,
          amount: formData.amount,
          client_id: formData.client?.id,
          since: getUnixTime(formData.since),
          facture_number: formData.facture_number,
        },
        submitCallback: response => {
          onSubmitForm(response)
          setIsFetching(false)
        },
      }),
    )
  }

  return (
    <form
      onKeyPress={onKeyPress}
      onSubmit={handleSubmitForm}
      className="inputForm modal"
    >
      <SearchAutocomplete
        label="Client"
        value={formData.client?.label}
        options={clientOptions}
        onChange={value => {
          customHandleChange('client', clientsList.find(propEq('id', value)))
        }}
      />

      <div className="formInput">
        <DatePicker
          inline
          locale="fr"
          selected={formData.since}
          onChange={value => customHandleChange('since', value)}
          dateFormat="MMMM d, yyyy"
        />
      </div>

      <FormInput
        label="Numéro de facture"
        name="facture_number"
        type="string"
        required
        value={formData.facture_number}
        onChange={setFormData}
      />

      <FormInput
        label="Montante"
        name="amount"
        type="number"
        min={1}
        required
        value={formData.amount}
        onChange={setFormData}
      />

      <div className="formFooter">
        {statusNode}
        <button type="submit" className="button" disabled={isFetching}>
          submit
        </button>
      </div>
    </form>
  )
}

AddDebtClientForm.defaultProps = {
  formData: {
    id: undefined,
    amount: 1,
    client: null,
    since: new Date(),
    facture_number: '',
  },
}

export default AddDebtClientForm
